import React from 'react';
import { css } from '@emotion/css';
import LargeOverflowMenu from '../general/LargeOverflowMenu';
import { OverflowOption } from '../general/overflowMenu/OverflowMenu';
import { color_shades_dark, color_shades_darkest, color_variants_main_bg } from '../../constants/colors';
import { CountButton } from '../../scenes/contentPage/components/CountButton';
const webhookIcon = require('../../content/images/webhooks/webhook-dark.svg');
const computerLockDark = require('../../content/images/data-sensitivity/computer-lock-large.svg');
const computerLockBlue = require('../../content/images/data-sensitivity/computer-lock-large-blue.svg');
interface ContentInfoOptionsProps {
    optionSelected: (s: string) => void
    manageWebhooks: () => void
    options: OverflowOption[]
    webhookCount: number
    showSensitiveConversation: boolean,
    toggleSensitiveConversation: () => void
    showDisabledForFeatures: boolean,
    toggleDisabledForFeatures: () => void
    flagsSelected?: number
}
const ContentInfoOptions: React.FC<ContentInfoOptionsProps> = (props) => (
    <div className={containerStyle}>
        <CountButton
            isActive={props.showSensitiveConversation}
            onClick={() => { props.toggleSensitiveConversation(); }}
            activeIcon={computerLockBlue}
            inactiveIcon={computerLockDark}
            showCount={false}
            text="Secure Conversation"
        />
        <CountButton
            isActive={props.showDisabledForFeatures}
            onClick={() => { props.toggleDisabledForFeatures(); }}
            showCount={true}
            text="Disabled for Features"
            count={props.flagsSelected}
        />
        <button onClick={(e) => { e.stopPropagation(); props.manageWebhooks(); }} type="button" className="webhook-pill ac-content-item-webhooks">
            <p>Webhooks</p>
            {
                props.webhookCount == 0 ?
                    <img src={webhookIcon} />
                    :
                    <span className="count-number">{props.webhookCount}</span>
            }
        </button>
        <LargeOverflowMenu className="ac-content-info-overflow" isActive={false} onOptionSelected={props.optionSelected} options={props.options} />
    </div>
);

const containerStyle = css`
    margin-left: auto;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    .webhook-pill {
        border-radius: 16px;
        border: 1px solid ${color_shades_dark};
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        height: 32px;
        padding: 0 24px;
        background: ${color_variants_main_bg};
        font-family: 'Muli';
        .count-number {
            color: white;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-top: 3px;
            margin-left: 8px;
            background: ${color_shades_darkest};
            font-family: 'Muli';
        }
        img {
            margin-left: 8px;
        }
    }
`;

export default ContentInfoOptions;