import styled from "@emotion/styled";
import React, { useEffect } from "react";
import ContentCard from "../../../components/structure/ContentCard";
import FormFieldsContainer from "../../../components/structure/FormFieldsContainer";
import { HighlightSection } from "../../formScenes/BaseConversationItemForm";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";
import TextField from "../../../components/forms/TextField";
import CheckboxField from "../../../components/forms/CheckboxField";


interface DisableForFeatureFlagOptionsProps {
    onChange: (flags: string[]) => void
    appContainer: ApplicationContainer;
    selectedFeatureFlags: string[];
    titleOverride?: string;
    descriptionOverride?: string;
}
const DisableForFeatureFlagOptions: React.FC<DisableForFeatureFlagOptionsProps> = (props: DisableForFeatureFlagOptionsProps) => {
    useEffect(() => {
        props.appContainer.loadFeatureFlags();
    }, []);
    const allFeatureFlags = props.appContainer.state.featureFlags;


    const handleFeatureFlagsChange = (flag: FeatureFlagModel) => {
        // manage check box
        const flagsCopy = [...(props.selectedFeatureFlags ?? [])];
        if (flagsCopy.includes(flag.id)) {
            flagsCopy.splice(flagsCopy.indexOf(flag.id), 1);
        } else {
            flagsCopy.push(flag.id);
        }
        props.onChange(flagsCopy);
    };
    return (
        <HighlightSection>
            <ContentCard title={props.titleOverride ?? "Disable this item if feature flags are present"}>
                <FormFieldsContainer>
                    <Description>
                        {props.descriptionOverride ?? "If a checked feature flag is present in this application, this content item will be disabled."}
                    </Description>
                </FormFieldsContainer>
                {allFeatureFlags.map((flag, index) => {
                    return (
                        <>
                            <CheckboxField
                                key={`KEY ${index}`}
                                name={flag.name}
                                label={flag.name}
                                checked={props.selectedFeatureFlags?.includes(flag.id)}
                                onChange={() => handleFeatureFlagsChange(flag)}
                            />
                        </>
                    )
                })}
            </ContentCard>
        </HighlightSection>
    );
};
const Description = styled.p`
    margin-bottom: 32px;
    display: flex;
`

export default DisableForFeatureFlagOptions;
