import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React, { useEffect, useState, FocusEvent } from 'react';
import FieldError from '../../../components/forms/FieldError';
import IntentSelector from './IntentSelector';
import SelectField from '../../../components/forms/SelectField';
import TextField from '../../../components/forms/TextField';
import Loader from '../../../components/general/Loader';
import TextOverflowMenu from '../../../components/general/overflowMenu/TextOverflowMenu';
import ToggleIndicator from '../../../components/general/ToggleIndicator';
import ContentCard from '../../../components/structure/ContentCard';
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection';
import FormFieldsContainer from '../../../components/structure/FormFieldsContainer';
import VerticalSeparator from '../../../components/structure/VerticalSeparator';
import { color_colors_ocean, color_shades_dark, color_shades_darker, color_shades_darkest } from '../../../constants/colors';
import ApplicationNlpEntityContainer from '../../../hooks/ApplicationNlpEntityContainer';
import InteractionModelContainer from '../../../hooks/InteractionModelContainer';
import LanguageModel from '../../../models/features/api/LanguageModel';
import IntentUpdate from '../../../models/interactionModel/api/IntentUpdate';
import InteractionModelUpdate from '../../../models/interactionModel/api/InteractionModelUpdate';
import CustomRequestEditableIntent from '../../../models/interactionModel/CustomRequestEditableIntent';
import EditableIntent from '../../../models/interactionModel/EditableIntent';
import EditableInteractionModel from '../../../models/interactionModel/EditableInteractionModel';
import EditableNlpEntity from '../../../models/nlpEntity/EditableNlpEntity';
import IntentItem from '../../interactionModel/components/IntentItem';
import { addEditableIntentToUpdate, buildEditableInteractionModel, isEmptyInteractionModelUpdate } from '../../interactionModel/components/InteractionModelForm';
import { PLATFORM_VOICIFY } from '../../../constants/platforms';
import ApplicationModel from '../../../models/applications/api/ApplicationModel';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';

const userIcon = require('../../../content/images/user.svg');
const inputGroupIcon = require('../../../content/images/content-tree-icons/content-level/input-stack.svg');
const deleteIcon = require('../../../content/images/content-explorer/delete.svg');

interface CustomRequestInputOptionsProps {
    languages: LanguageModel[];
    application: ApplicationModel;
    updateValues: (requstNames: string, requestTypes: string, platformFilter: string) => any;
    requestTypes: string;
    requestNames: string;
    platformFilter: string;
    isDisabled: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: FocusEvent<any>) => void;
    setFieldValue: (name: string, value: any) => any;
    appContainer: ApplicationContainer
};

const CustomRequestInputOptions: React.FC<CustomRequestInputOptionsProps> = (props) => {
    const [showIntentEditor, setShowIntentEditor] = useState(false);
    const [interactionModel, setInteractionModel] = useState({} as EditableInteractionModel);
    const [nlpEntities, setNlpEntities] = useState([] as EditableNlpEntity[]);
    const [isAddingIntent, setIsAddingIntent] = useState(false);
    const [showPlatforms, setShowPlatforms] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [initialNlpLoadComplete, setInitialNlpLoadComplete] = useState(false);
    const [editingIntentIndex, setEditingIntentIndex] = useState(null as number);
    const [selectedRequestTypeOption, setSelectedRequestTypeOption] = useState("Intent" as "Intent" | "Custom");

    // state that tracks requestNames
    const [selectedIntents, setSelectedIntents] = useState([] as CustomRequestEditableIntent[]);

    // state that tracks platformFilter
    const [platforms, setPlatforms] = useState([] as { option: string, platform: string }[]);

    const interactionModelContainer = InteractionModelContainer.useContainer();
    const nlpEntitiesContainer = ApplicationNlpEntityContainer.useContainer();

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        const promises = [];
        promises.push(nlpEntitiesContainer.getApplicationNlpEntities(props.application?.id, props.languages));
        promises.push(interactionModelContainer.loadInteractionModels(props.application?.id, props.languages));
        await Promise.all(promises);
        setInitialNlpLoadComplete(true);
    }

    useEffect(() => {
        if (!loaded
            && initialNlpLoadComplete
            && !interactionModelContainer.isLoading
            && !nlpEntitiesContainer.isLoading
            && nlpEntitiesContainer.currentApplicationId === props.application?.id
            && interactionModelContainer.currentApplicationId === props.application?.id) {
            if (props.requestTypes) {
                setSelectedRequestTypeOption("Custom");
            }
            if (props.requestNames) {
                mapRequestNamesToSelectedIntents(props.requestNames);
            }
            if (props.platformFilter) {
                const platformArray = props.platformFilter.split(",") ?? [];
                const newPlatforms = [];
                for (let i = 0; i < platformArray.length; ++i) {
                    const platform = platformArray[i];
                    if (platform === "GoogleAssistant" || platform === "Alexa") {
                        newPlatforms.push({ option: platform, platform: platform })
                    } else {
                        newPlatforms.push({ option: "custom", platform: platform });
                    }
                }
                setPlatforms(newPlatforms);
            }
            setLoaded(true);
        }
    }, [interactionModel,
        nlpEntitiesContainer.currentApplicationId,
        interactionModelContainer.currentApplicationId,
        interactionModelContainer.isLoading,
        nlpEntitiesContainer.isLoading,
        initialNlpLoadComplete]);

    const mapRequestNamesToSelectedIntents = (requestNames: string) => {
        const newSelectedIntents = [] as CustomRequestEditableIntent[];
        let requestNameArray = requestNames.split(",") ?? [];
        requestNameArray = requestNameArray.map(s => s.trim());
        const intents = Object.entries(interactionModel);

        for (let i = 0; i < requestNameArray.length; ++i) {
            const requestName = requestNameArray[i];
            const idMatches = intents?.find(entry => entry[1].locales.some(l => l.intentId === requestName));
            const intentById = idMatches ? idMatches[1] : null;
            const nameMatches = intents?.find(entry => entry[1].name[PLATFORM_VOICIFY] === requestName || entry[1].displayName === requestName);
            const intentByName = nameMatches ? nameMatches[1] : null;

            if (intentById && intentById.isCustom) {
                // For custom intents we capture intent id in requestNames
                const intentToMap: CustomRequestEditableIntent = {
                    intent: intentById,
                    isUsingExternalIntent: false,
                    externalIntent: '',
                    ids: '',
                };
                newSelectedIntents.push(intentToMap);
            }
            else if (intentByName && !intentByName?.isCustom) {
                // For prebuilt intents we capture intent's name in requestNames
                const intentToMap: CustomRequestEditableIntent = {
                    intent: intentByName,
                    isUsingExternalIntent: false,
                    externalIntent: '',
                    ids: '',
                };
                newSelectedIntents.push(intentToMap);
            }
            else {
                const intentToMap: CustomRequestEditableIntent = {
                    intent: null,
                    isUsingExternalIntent: true,
                    externalIntent: requestName,
                    ids: ''
                }
                newSelectedIntents.push(intentToMap);
            }
        }

        if (!newSelectedIntents.length) {
            newSelectedIntents.push({
                intent: null,
                isUsingExternalIntent: false,
                externalIntent: '',
                ids: ''
            })
        }
        setSelectedIntents(newSelectedIntents)
    }

    const handleAddSelectedIntent = () => {
        setSelectedIntents([...selectedIntents,
        {
            intent: null,
            isUsingExternalIntent: false,
            externalIntent: '',
            ids: ''
        }
        ])
    }

    const mapPlatformFilter = (platformsToBeMapped: { option: string, platform: string }[]) => {
        let platformStrings: string[] = [];
        platformsToBeMapped.forEach(p => {
            if (p.platform?.length)
                platformStrings.push(p.platform);
        })
        if (platformStrings.length)
            return platformStrings.join(",");

        return null;
    }

    const mapRequestNames = (intentsToBeMapped: CustomRequestEditableIntent[]) => {
        const requestNames: string[] = [];

        intentsToBeMapped.forEach(i => {
            let requestName = "";
            if (i.isUsingExternalIntent)
                requestName = i.externalIntent;
            else if (i.intent?.isCustom) {
                // For custom intent we capture intent id in requestName
                const ids = i.intent.locales?.map(l => l.intentId);
                requestName = ids?.join(",");
            }
            else {
                // For prebuilt intents we capture intent's name["voicify"] property in requestName
                requestName = i.intent?.name[PLATFORM_VOICIFY] ?? i.intent?.displayName;
            }

            if (requestName?.length)
                requestNames.push(requestName);
        })
        if (requestNames.length)
            return requestNames.join(",");

        return null;
    }

    useEffect(() => {
        if (interactionModelContainer.interactionModels?.length && !loaded && interactionModelContainer.currentApplicationId === props.application?.id) {
            const model = buildEditableInteractionModel(interactionModelContainer.interactionModels, props.languages);
            setInteractionModel(model);
        }
    }, [interactionModelContainer.interactionModels, interactionModelContainer.currentApplicationId])

    useEffect(() => {
        if (nlpEntitiesContainer.applicationNlpEntities?.length) {
            const formEntities = nlpEntitiesContainer.applicationNlpEntities.map(e => {
                let formEntity: EditableNlpEntity = { ...e };
                return formEntity;
            });
            setNlpEntities(formEntities);
        }
    }, [nlpEntitiesContainer.applicationNlpEntities])

    const handleAddIntent = (idx) => {
        const newName = "New Intent";
        let intentNewKey = newName;
        props.languages?.forEach(l => {
            intentNewKey = intentNewKey + "|" + l.shortCode;
        })

        const newIntent: CustomRequestEditableIntent = {
            intent: {
                key: intentNewKey,
                originalDisplayName: newName,
                displayName: newName,
                utterances: [],
                slots: [],
                isDisabled: false,
                updatedDisabledState: false,
                isCustom: true,
                locales: props.languages.map(l => ({ shortCode: l.shortCode, intentId: newName })),
                isDeleted: false,
                isAdded: true,
                isModified: true,
                requiresExactUtteranceMatch: false,
                disabledForFeatureFlagIds: [],
            },
            isUsingExternalIntent: false,
            externalIntent: '',
            ids: ''
        };
        setEditingIntentIndex(selectedIntents.length);
        setSelectedIntents([...selectedIntents, newIntent]);
        setShowIntentEditor(true);
        setIsAddingIntent(true);
    }

    const handleSelectExternalIntent = (idx) => {
        const newSelectedIntents = [...selectedIntents];
        newSelectedIntents[idx].isUsingExternalIntent = true;
        newSelectedIntents[idx].externalIntent = "";
        setSelectedIntents(newSelectedIntents);
    }

    const handleIntentSelectorClick = (idx: number, values: string[]) => {
        if (values?.length) {
            const selectedOption = values[0];
            if (selectedOption === "__newIntent") {
                handleAddIntent(idx);
            } else if (selectedOption === "__externalIntent") {
                handleSelectExternalIntent(idx);
            } else if (selectedOption === "__customIntents") {
                // do nothing, clicking on group
            } else if (selectedOption === "__preBuiltIntents") {
                // do nothing, clicking on group
            } else if (selectedOption in interactionModel) {
                setIsAddingIntent(false);
                const newSelectedIntents = [...selectedIntents];
                newSelectedIntents[idx].isUsingExternalIntent = false;
                // Option value is intent.key
                newSelectedIntents[idx].intent = interactionModel[selectedOption];
                setSelectedIntents(newSelectedIntents);
                props.updateValues(mapRequestNames(newSelectedIntents), props.requestTypes, mapPlatformFilter(platforms));
            }
        }
    }
    const handleIntentModalUpdate = (displayName: string, intent: EditableIntent): string => {
        let error: string = null;
        const newSelectedIntents = [...selectedIntents];
        newSelectedIntents[editingIntentIndex].intent = intent;
        newSelectedIntents[editingIntentIndex].isUsingExternalIntent = false;
        setSelectedIntents(newSelectedIntents);

        props.updateValues(mapRequestNames(newSelectedIntents), props.requestTypes, mapPlatformFilter(platforms));
        const newInteractionModel = { ...interactionModel };
        delete newInteractionModel[intent.key];
        newInteractionModel[intent.key] = intent;
        setInteractionModel(newInteractionModel);

        return error;
    }
    const handleIntentModalSubmit = async () => {
        const promises = [];
        for (let i = 0; i < props.languages?.length; i++) {
            const language = props.languages[i];
            let update = {
                intents: {},
                locale: language?.shortCode,
                disableIntents: [],
                enableIntents: [],
                removeCustomIntents: [],
                addCustomIntents: []
            } as InteractionModelUpdate;

            let intentUpdate = {
                addedUtterances: [],
                removedUtterances: [],
                addedSlots: [],
                removedSlots: [],
                updatedDisplayName: ""
            } as IntentUpdate;
            update = addEditableIntentToUpdate(update, intentUpdate, selectedIntents[editingIntentIndex].intent, language);
            if (!isEmptyInteractionModelUpdate(update)) {
                promises.push(interactionModelContainer.updateInteractionModel(props.application?.id, language.shortCode, update));
            }
        }
        if (promises.length) {
            await Promise.all(promises);
        }

        const result = await interactionModelContainer.loadInteractionModels(props.application?.id, props.languages);
        if (result.resultType == "Ok") {
            const model = buildEditableInteractionModel(result.data, props.languages);
            setInteractionModel(model);
            const newSelectedIntent = Object.entries(model)?.find(entry => entry[1].key === selectedIntents[editingIntentIndex].intent?.key)[1];
            const newSelectedIntents = [...selectedIntents];
            newSelectedIntents[editingIntentIndex].intent = newSelectedIntent;
            newSelectedIntents[editingIntentIndex].isUsingExternalIntent = false;
            setSelectedIntents(newSelectedIntents);
            props.updateValues(mapRequestNames(newSelectedIntents), props.requestTypes, mapPlatformFilter(platforms));
            setIsAddingIntent(false);
            setShowIntentEditor(false);
        }
    }
    const handlePlatformSelectChange = (value: string, idx: number) => {
        const newPlatforms = [...platforms];
        newPlatforms[idx].option = value;
        if (value !== "custom")
            newPlatforms[idx].platform = value;
        else
            newPlatforms[idx].platform = "";
        setPlatforms(newPlatforms);
        props.updateValues(mapRequestNames(selectedIntents), props.requestTypes, mapPlatformFilter(newPlatforms));
    }
    const handleCustomPlatformChange = (value: string, idx: number) => {
        const newPlatforms = [...platforms];
        newPlatforms[idx].platform = value;
        setPlatforms(newPlatforms);
        props.updateValues(mapRequestNames(selectedIntents), props.requestTypes, mapPlatformFilter(newPlatforms));
    }
    const handleToggleShowPlatforms = () => {
        if (!showPlatforms && !platforms.length) {
            setPlatforms([...platforms, { option: "", platform: "" }])
        }
        setShowPlatforms(!showPlatforms);
    }
    const handlePlatformDelete = (idx) => {
        const newPlatforms = platforms.filter((p, i) => i !== idx);
        setPlatforms(newPlatforms);
        props.updateValues(mapRequestNames(selectedIntents), props.requestTypes, mapPlatformFilter(newPlatforms));
    }
    const updateExternalIntent = (idx, newVal) => {
        const newSelectedIntents = [...selectedIntents];
        newSelectedIntents[idx].externalIntent = newVal;
        setSelectedIntents(newSelectedIntents);
        props.updateValues(mapRequestNames(newSelectedIntents), props.requestTypes, mapPlatformFilter(platforms));
    }
    const handleIntentDelete = (idx) => {
        const newSelectedIntents = selectedIntents.filter((x, i) => idx !== i);
        setSelectedIntents(newSelectedIntents);
        props.updateValues(mapRequestNames(newSelectedIntents), props.requestTypes, mapPlatformFilter(platforms));
    }
    const handleRequestTypeSelectorChange = (option) => {
        setSelectedRequestTypeOption(option as "Custom" | "Intent");
        if (option === "Intent") {
            mapRequestNamesToSelectedIntents(props.requestNames);
            props.setFieldValue("requestTypes", '');
        }
    }
    const renderRequestTypeSelector = () => {
        return (<TextOverflowMenu
            clickableText={selectedRequestTypeOption}
            className={requestTypeStyle}
            menuClassName={requestTypeMenuStyle}
            toggleClassName={toggleIndicatorStyle}
            label="Request Type: "
            onOptionSelected={handleRequestTypeSelectorChange}
            options={[{ label: "Intent" }, { label: "Custom" }]} />);
    }
    return (
        <>
            <ContentCollapsingSection titleRightRowComponent={renderRequestTypeSelector()} title="Input Requirements" icon={userIcon} id="input">
                <ContentCard title="Input Options" icon={inputGroupIcon} id="inputOptions">
                    {
                        !loaded ?
                            <LoaderHolder><Loader /></LoaderHolder>
                            :
                            <>
                                {
                                    selectedRequestTypeOption === "Intent" ?
                                        <>
                                            <FieldError name={"requestNames"} />
                                            {selectedIntents.map((i, idx) =>
                                                <InputOptionFieldContainer key={idx}>
                                                    <IntentActionRow>
                                                        {(!i.isUsingExternalIntent && i.intent) &&
                                                            <ClickableText onClick={() => { setShowIntentEditor(true); setEditingIntentIndex(idx); }}>
                                                                Manage this intent
                                                            </ClickableText>}
                                                        <IntentDeleteIcon onClick={() => handleIntentDelete(idx)} src={deleteIcon} />
                                                    </IntentActionRow>
                                                    <IntentSelector
                                                        label='Intent'
                                                        selectedDisplayText={i.isUsingExternalIntent ? "External Intent" : (i.intent?.displayName ? i.intent?.displayName : "Select an Intent")}
                                                        interactionModel={interactionModel}
                                                        handleIntentChange={(x) => handleIntentSelectorClick(idx, x)}
                                                        selectedIntent={i.intent} />
                                                    {
                                                        i.isUsingExternalIntent && (
                                                            <IndentedTextFieldHolder>
                                                                <div className='vertical-line' />
                                                                <div className='horizontal-line' />
                                                                <TextField useForm={false}
                                                                    placeholder="Enter comma separated intent names"
                                                                    value={i.externalIntent}
                                                                    label="External Intent"
                                                                    onChange={(e) => updateExternalIntent(idx, e.target.value)} />
                                                            </IndentedTextFieldHolder>
                                                        )
                                                    }
                                                </InputOptionFieldContainer>
                                            )}
                                        </>
                                        :
                                        <FormFieldsContainer>
                                            <TextField
                                                id="requestTypes"
                                                disabled={props.isDisabled}
                                                name="requestTypes"
                                                value={props.requestTypes}
                                                tooltip="The native name of a request type such as IntentRequest or Connections.Response"
                                                toolTipPosition="right"
                                                label="Request Types"
                                                placeholder="A comma separated list of native request types. Example: IntentRequest"
                                                onChange={props.onChange}
                                                onBlur={props.onBlur} />
                                            <TextField
                                                id="requestNames"
                                                disabled={props.isDisabled}
                                                name="requestNames"
                                                value={props.requestNames}
                                                label="Request Names"
                                                tooltip="The native name of the request such as the Intent name or action name"
                                                toolTipPosition="right"
                                                placeholder="A comma separated list of native request names. Example: TelephoneIntent"
                                                onChange={props.onChange}
                                                onBlur={props.onBlur} />
                                        </FormFieldsContainer>
                                }
                                <PlatformRow>
                                    {
                                        selectedRequestTypeOption === "Intent" ?
                                            <AddAnIntentText onClick={handleAddSelectedIntent}>
                                                + Add an Intent
                                            </AddAnIntentText>
                                            :
                                            null
                                    }
                                    <PlatformChipWrapper onClick={handleToggleShowPlatforms}>
                                        <PlatformText>Platform:</PlatformText>
                                        {platforms?.length ? <PlatformChip>{platforms.length}</PlatformChip> : <PlatformAnyText>Any</PlatformAnyText>}
                                        <PlatformToggleIndicator direction={showPlatforms ? "up" : "down"} />
                                    </PlatformChipWrapper>
                                </PlatformRow>
                                <FieldError name={"platformFilter"} />
                                {showPlatforms &&
                                    <PlatformsList>
                                        {platforms.length ? <p>This input will be exclusive to the platforms listed below:</p> : <></>}
                                        {platforms.map((p, idx) =>
                                            <InputOptionFieldContainer key={idx}>
                                                <PlatformDeleteIcon onClick={() => handlePlatformDelete(idx)} src={deleteIcon} />
                                                <SelectField
                                                    clearable={false}
                                                    key={`platform-${idx}`}
                                                    useForm={false}
                                                    onChange={(e: { value: string }) => { handlePlatformSelectChange(e.value, idx) }}
                                                    onBlur={() => { }}
                                                    value={p.option}
                                                    label="Platform"
                                                    placeholder="Select a platform"
                                                    useFormTheme
                                                    options={[
                                                        { value: "Alexa", label: "Alexa" },
                                                        { value: "GoogleAssistant", label: "Google Assistant" },
                                                        { value: "custom", label: "Custom" }
                                                    ]}
                                                />
                                                {
                                                    p.option === "custom" &&
                                                    <CustomPlatformHolder>
                                                        <div className='vertical-line' />
                                                        <div className='horizontal-line' />
                                                        <TextField useForm={false}
                                                            placeholder="Enter a platform"
                                                            value={p.platform}
                                                            onBlur={() => { }}
                                                            label="Platform Name"
                                                            onChange={(e) => handleCustomPlatformChange(e.target.value, idx)} />
                                                    </CustomPlatformHolder>
                                                }
                                            </InputOptionFieldContainer>)}
                                        <ClickableText onClick={() => setPlatforms([...platforms, { option: "", platform: "" }])}>
                                            + Add a Platform
                                        </ClickableText>
                                    </PlatformsList>
                                }
                            </>
                    }

                </ContentCard>
            </ContentCollapsingSection>
            {
                showIntentEditor &&
                <IntentItem
                    enhancedIntentMatchingEnabled={false}
                    {...props}
                    displayType="Modal"
                    nlpEntities={nlpEntities}
                    intent={selectedIntents[editingIntentIndex].intent}
                    isLoading={!loaded || interactionModelContainer.isLoading}
                    updateIntent={handleIntentModalUpdate}
                    toggleActivationByKey={() => { }}
                    applicationId={props.application?.id}
                    languages={props.languages}
                    idx={0}
                    isActive={true}
                    key={0}
                    handleClose={() => setShowIntentEditor(false)}
                    handleSubmit={handleIntentModalSubmit} />
            }
        </>
    );

};

const requestTypeStyle = css`
    margin-left: auto;
`;

const toggleIndicatorStyle = css`
    margin-top: 6px !important;
    margin-left: 8px !important;
`;

const requestTypeMenuStyle = css`
    margin-left: -6px;
`;

const InputOptionFieldContainer = styled.div`
    position: relative;
`;

const PlatformDeleteIcon = styled.img`
    position: absolute;
    cursor: pointer;
    right: 16px;
    height: 20px;
`;

const IntentActionRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0px;
    z-index: 1;
`;

const IntentDeleteIcon = styled.img`
    cursor: pointer;
    height: 20px;
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid ${color_shades_dark};
`;


const LoaderHolder = styled.div`
    width: 100%;
    height: 400px;
    position: relative;
`;

const IndentedTextFieldHolder = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    .vertical-line {
        height: 67px;
        border-right: 1px solid ${color_shades_dark};
        margin-left: 16px;
        margin-top: -9px;
    }
    .horizontal-line {
        border-bottom: 1px solid ${color_shades_dark};
        width: 16px;
        margin-bottom: 52px;
    }
`;

const CustomPlatformHolder = styled(IndentedTextFieldHolder)`
    margin-top: -23px;
`;

const ClickableText = styled.div`
    color: ${color_colors_ocean};
    font-size: 12px;
    cursor: pointer;
`;

const AddAnIntentText = styled(ClickableText)`
    font-size: 14px;
`;

const PlatformRow = styled.div`
    margin-top: 16px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
`;

const PlatformText = styled.div`
    color: ${color_shades_darker};
`;

const PlatformChip = styled.div`
    min-width: 24px;
    text-align: center;
    background: ${color_shades_darkest};
    color: white;
    margin-left: 4px;
    border-radius: 16px;
    font-size: 12px;
    padding: 2px 8px 0px 8px;
    line-height: 16px;
`;

const PlatformChipWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    cursor: pointer;
`;

const PlatformToggleIndicator = styled(ToggleIndicator)`
    margin: 8px 0 0 8px;
`;

const PlatformsList = styled.div`
    border-top: 1px solid ${color_shades_dark};
    border-bottom: 1px solid ${color_shades_dark};
    padding: 16px 0 16px 0;
    p {
        margin-bottom: 8px;
    }
`;

const PlatformAnyText = styled.div`
    font-style: italic;
    color: ${color_shades_darkest};
    margin-left: 4px;
`;

export default CustomRequestInputOptions;
