import styled from "@emotion/styled";
import React from "react";
import { color_colors_ocean, color_gradients_silver_shift, color_shades_darkest, color_text_default, color_text_link, color_variants_ocean_light_opaque } from "../../../constants/colors";
import { style_border_default } from "../../../constants/stylesValues";

interface CountButtonProps {
    isActive: boolean;
    onClick: (e?: any) => void;
    showCount?: boolean;
    count?: number;
    activeIcon?: string;
    inactiveIcon?: string;
    className?: string;
    text: string
}
export const CountButton = (props: CountButtonProps) => {
    const hasImage = props.activeIcon || props.inactiveIcon;
    return (
        <StyledButton
            type="button"
            className={`${props.isActive ? 'active' : 'inactive'} ${props.className ?? ''}`}
            onClick={(e) => { e.stopPropagation(); props.onClick(e); }}
        >
            <p>{props.text}</p>
            {props.showCount ?
                props.count > 0
                    ? <span className="count-number">{props.count}</span>
                    : hasImage ? <img src={props.isActive ? props.activeIcon : props.inactiveIcon} /> : <></>
                : hasImage ? <img src={props.isActive ? props.activeIcon : props.inactiveIcon} /> : <></>
            }
        </StyledButton>
    );
};

const StyledButton = styled.button`
    height: 32px;
        padding: 0 24px;
        background: ${color_gradients_silver_shift};
        border: ${style_border_default};
        box-shadow: none;
        border-radius: 16px;
        box-sizing: border-box;
        color: ${color_text_default};
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Muli';
        
        .count-number {
            color: white;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-left: 8px;
            background: ${color_shades_darkest};
            font-family: 'Muli';
        }
        &.active {
            background: ${color_variants_ocean_light_opaque};
            border-color: ${color_colors_ocean};
            color: ${color_text_link};
        }
        img {
            margin-left: 8px;
            max-height: 20px;
        }
`;