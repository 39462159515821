import { withFormik } from "formik";
import * as yup from 'yup';
import moment from 'moment';
import LatestMessageForm, { LatestMessageContentFormProps, LatestMessageContentFormData } from '../../formScenes/LatestMessageForm';

const UpdateLatestMessageContentForm = withFormik<LatestMessageContentFormProps, LatestMessageContentFormData>({
    mapPropsToValues: (props: LatestMessageContentFormProps) => {
        var values: LatestMessageContentFormData = {
            title: props.latestMessage.title,
            category: props.latestMessage.category,
            startDate: moment(props.latestMessage.startDate).format('YYYY-MM-DD'),
            endDate: moment(props.latestMessage.endDate).format('YYYY-MM-DD'),
            responses: props.latestMessage.responses.length > 0 ? props.latestMessage.responses : [{ content: '' }],
            requiresParent: props.latestMessage.requiresParent,
            languages: props.latestMessage.languages,
            isLive: props.latestMessage.isLive,
            webhookChainingType: props.latestMessage.webhookChainingType,
            sensitiveConversationChange: props.latestMessage.sensitiveConversationChange ?? "None",
            disabledForFeatureFlagIds: props.latestMessage?.disabledForFeatureFlagIds,
        }
        return values;
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        category: yup.string().required("You must enter a category"),
        startDate: yup.date().required("You must enter a start date"),
        endDate: yup.date().required("You must enter an end date")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.latestMessage, values));
    }
})(LatestMessageForm);

export default UpdateLatestMessageContentForm;