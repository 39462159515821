import { FormikErrors, withFormik } from "formik";
import _ from "lodash";
import LanguageModel from "../../../models/features/api/LanguageModel";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import InteractionModelEditorWrapper from "./InteractionModelEditorWrapper";
import * as yup from 'yup';
import EditableInteractionModel from "../../../models/interactionModel/EditableInteractionModel";
import InteractionModel from "../../../models/interactionModel/api/InteractionModel";
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import Utterance, { UtteranceType } from "../../../models/interactionModel/Utterance";
import Intent from "../../../models/interactionModel/api/Intent";
import InteractionModelUpdate from "../../../models/interactionModel/api/InteractionModelUpdate";
import IntentUpdate from "../../../models/interactionModel/api/IntentUpdate";
import Slot from "../../../models/interactionModel/Slot";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import NlpEntityModel from "../../../models/nlpEntity/api/NlpEntityModel";
import NlpEntityValueModel from "../../../models/nlpEntity/api/NlpEntityValueModel";
import PhraseSlot from "../../../models/interactionModel/PhraseSlot";
import GroupedUtterance from "../../../models/interactionModel/GroupedUtterance";
import { LARGE_ENTITY_VALUES_COUNT } from "../../../hooks/ApplicationNlpEntityContainer";
import Papa from 'papaparse';
import ApplicationContainer from "../../../state/containers/ApplicationContainer";

export const maxNlpEntityNameSize: number = 50;

// Shape of form values in InteractionModelForm
export interface InteractionModelFormData {
    nlpEntities: EditableNlpEntity[];
    interactionModel: EditableInteractionModel;
    enableEnhancedIntentMatching?: boolean;
    disableEnhancedIntentMatching?: boolean;
    enhancedIntentMatchingPrompt?: string;
    useEnhancedIntentMatching?: boolean
    updatedEnhancedIntentMatchingPrompt?: string;
};

export interface InteractionModelFormProps {
    applicationId: string;
    languages: LanguageModel[];
    allAppLanguages: LanguageModel[];
    interactionModels: InteractionModel[];
    applicationNlpEntities: EditableNlpEntity[];
    prebuiltNlpEntities: NlpEntityModel[];
    onSubmit: (request: InteractionModelUpdate[], entities: EditableNlpEntity[]) => void;
    appContainer: ApplicationContainer;
};

// Wrap our form with the withFormik HoC
const InteractionModelForm = withFormik<InteractionModelFormProps, InteractionModelFormData>({
    displayName: "InteractionModelForm",
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: yup.object().shape({
        nlpEntities: yup.array()
            .of(yup.object().shape({
                isDeleted: yup.boolean(),
                name: yup.string().when("isDeleted", {
                    is: false,
                    then: yup.string()
                        .max(maxNlpEntityNameSize, `Validation: NLP entity name must not exceed ${maxNlpEntityNameSize} characters `)
                        .required('Validation: NLP Entity Name is required '),
                }),
                type: yup.string().required("Validation: NLP Entity Type is required "),
            }))
    }),
    // Transform outer props into form values
    mapPropsToValues: props => {
        const formEntities = props.applicationNlpEntities.map(e => {
            let formEntity: EditableNlpEntity = { ...e };
            return formEntity;
        })
        const formInteractionModel = buildEditableInteractionModel(props.interactionModels, props.languages);

        var im = props.interactionModels.find(i => i.locale === props.languages[0]?.shortCode);
        const values: InteractionModelFormData = {
            nlpEntities: formEntities,
            interactionModel: formInteractionModel,
            enableEnhancedIntentMatching: false,
            disableEnhancedIntentMatching: false,
            useEnhancedIntentMatching: im?.useEnhancedIntentMatching,
            enhancedIntentMatchingPrompt: im?.enhancedIntentMatchingPrompt
        };

        return values;
    },
    // Add a custom validation function (this can be async)
    validate: (values: InteractionModelFormData, props) => {
        let errors: FormikErrors<InteractionModelFormData> = {};

        const intentsFieldsErrors = validateIntentsFormFields(values);
        if (intentsFieldsErrors && Object.keys(intentsFieldsErrors).length > 0) {
            errors = intentsFieldsErrors;
        }

        const entitiesFieldsErrors = validateNlpEntitiesFormFields(values);
        if (entitiesFieldsErrors && Object.keys(entitiesFieldsErrors).length > 0) {
            errors = { ...errors, ...entitiesFieldsErrors };
        }

        // Returned errors object must be empty for validation to succeed
        return errors;
    },
    /** Form submit handler https://formik.org/docs/guides/form-submission */
    handleSubmit: async (values, { props, setSubmitting }) => {
        let updates = [] as InteractionModelUpdate[];
        for (let i = 0; i < props.languages.length; ++i) {
            const language = props.languages[i];
            const update = convertToInteractionModelLocaleUpdate(values, language);
            if (update)
                updates.push(update);
        }
        const modifiedEntities = values.nlpEntities?.filter(e => e.isModified || e.isDeleted || e.isAdded);
        await props.onSubmit(updates, modifiedEntities);
        setSubmitting(false);
    }
})(InteractionModelEditorWrapper);

const validateIntentsFormFields = (values: InteractionModelFormData): FormikErrors<InteractionModelFormData> => {
    let errors: FormikErrors<InteractionModelFormData> = {};
    let intentsErrorsObject: FormikErrors<EditableInteractionModel> = {};

    const entities = values?.nlpEntities?.filter(e => !e.isDeleted);
    const mergedInteractionModel = { ...values?.interactionModel };
    const intentNameKeys = Object.keys(mergedInteractionModel);

    for (let intentIdx = 0; intentIdx < intentNameKeys?.length; intentIdx++) {
        const intentKey = intentNameKeys[intentIdx];
        const intent: EditableIntent = mergedInteractionModel[intentKey];

        // No need to validate deleted intent
        if (intent.isDeleted) continue;

        const intentName = intent.displayName;
        // We do not really "edit" slots or utterances when creating IM Update - we delete old one and add a new 
        // Get all utterances
        const intentUtterances = intent.utterances;
        // Get non deleted slots
        const intentSlots = intent.slots?.filter(s => !s.isDeleted);
        // Get required slots
        const requiredSlots = intentSlots?.filter(s => s.required);

        const intentUsedSlotNames: string[] = [];

        let utterancesErrorsArray: FormikErrors<Utterance>[] = [];
        let slotsErrorsArray: FormikErrors<EditableSlot>[] = [];

        const utteranceGroups: GroupedUtterance[] = [];
        // We display one utterance per multiple languages
        intentUtterances?.forEach((utterance) => {
            const phrase = utterance.phrase;
            const existingGroup = utteranceGroups.find(u => u.phrase == phrase);
            if (existingGroup) {
                existingGroup.languages.push(utterance.language);
            }
            else {
                utteranceGroups.push({ ...utterance, languages: [utterance.language] });
            }
        });

        for (let utteranceIdx = 0; utteranceIdx < utteranceGroups?.length; utteranceIdx++) {
            const utterance = utteranceGroups[utteranceIdx];
            // In order to keep the correct index for field errors we need to use utteranceGroups
            utterancesErrorsArray.push(null);
            const phrase = utterance?.phrase;

            // No need to validate Deleted utterance, but we need to keep it in array for correct field errors index position
            if (utterance.changeType === "Removed") continue;
            const utteranceSlots = extractSlots(phrase);
            const utteranceSlotNames = utteranceSlots?.map(s => s.getSlotName());

            // Find a list of all slot names used in non deleted utterances for further slot validations
            if (utteranceSlotNames?.length > 0) {
                intentUsedSlotNames.push(...utteranceSlotNames);
            }

            if (!phrase || phrase.length < 1) {
                utterancesErrorsArray[utteranceIdx] = { phrase: `Phrase is required ` };
            }

            // Check if utterance is missing a required non deleted slot
            const missingSlots = _.difference(requiredSlots?.map(s => s.name), utteranceSlotNames);
            if (missingSlots?.length > 0) {
                utterancesErrorsArray[utteranceIdx] = { phrase: `Missing required slot(s) ` };
            }

            // Check if utterance has non existing slot
            const nonExistentSlots = _.difference(utteranceSlotNames, intentSlots?.map(s => s.name))
            if (nonExistentSlots?.length > 0) {
                utterancesErrorsArray[utteranceIdx] = { phrase: `Unknown slot(s) ` };
            }

            if (intent.isCustom) {
                // Check if custom intent's utterance's slots have valid NLP Entity?
                const slots = intentSlots?.filter(s => utteranceSlotNames?.includes(s.name));
                slots?.forEach(s => {
                    const valid = validateSlotNlpEntityName(s, entities);

                    if (!valid) {
                        utterancesErrorsArray[utteranceIdx] = { phrase: `Invalid slot(s) ` };
                    }
                    else {
                        // What about slot's entities existing in intent locales?
                        const localeEntities = getNlpEntitiesInLocales(entities, intent.locales?.map(l => l.shortCode));
                        const slotEntityFoundInAllLocales = validateSlotNlpEntityName(s, localeEntities);
                        if (!slotEntityFoundInAllLocales) {
                            utterancesErrorsArray[utteranceIdx] = { phrase: `Invalid slot(s) entity ` };
                        }
                    }
                })
            }
        }

        for (let slotIdx = 0; slotIdx < intentSlots?.length; slotIdx++) {
            const slot: EditableSlot = intentSlots[slotIdx];
            // For intent slots validation we do not need to keep exact fields index
            // This validation for displaying an error on IM intent item component
            // The actual slots form fields validation is happening in SlotsEditorForm

            if (intent.isCustom) {
                const entityName = slot.entityName;
                const slotEntityFound = validateSlotNlpEntityName(slot, entities);

                // Check if custom intent's non deleted slot that is used in any utterance is missing a valid NLP entity
                if (!slotEntityFound && intentUsedSlotNames.includes(slot.name)) {
                    const nlpEntityError = `Slot "${slot.name}" in intent "${intentName}" is used in some utterance(s) but it's entity "${entityName}" is invalid `;
                    slotsErrorsArray[slotIdx] = { entityName: nlpEntityError };
                }

                if (slotEntityFound) {
                    // What about slot's entities existing in intent locales?
                    const localeEntities = getNlpEntitiesInLocales(entities, intent.locales?.map(l => l.shortCode));
                    const slotEntityFoundInAllLocales = validateSlotNlpEntityName(slot, localeEntities);
                    if (!slotEntityFoundInAllLocales && intentUsedSlotNames.includes(slot.name)) {
                        const nlpEntityError = `Slot "${slot.name}" in intent "${intentKey}" is used in some utterance(s) but it's entity "${entityName}" does not have some of intent language(s) selected `;
                        slotsErrorsArray[slotIdx] = { entityName: nlpEntityError };
                    }
                }
            }

            const slotNameMatch = intentSlots?.filter((s, idx) =>
                idx != slotIdx
                && !s.isDeleted
                && s.name.trim().toLowerCase() == slot.name?.trim().toLowerCase());

            if (slotNameMatch?.length > 0) {
                const slotNameError = `Duplicate slot name "${slot.name}" found in "${intentName}" intent `;
                if (slotsErrorsArray[slotIdx]) {
                    slotsErrorsArray[slotIdx] = { ...slotsErrorsArray[slotIdx], name: slotNameError };
                }
                else {
                    slotsErrorsArray[slotIdx] = { name: slotNameError };
                }
            }
        }

        if (utterancesErrorsArray?.length > 0 && utterancesErrorsArray?.some(e => !!e)) {
            const utterancesErrorsObject = { utterances: utterancesErrorsArray };
            if (intentsErrorsObject[intentKey]) {
                intentsErrorsObject[intentKey] = { ...intentsErrorsObject[intentKey], ...utterancesErrorsObject };
            }
            else {
                intentsErrorsObject[intentKey] = utterancesErrorsObject;
            }
        }

        if (slotsErrorsArray?.length > 0 && slotsErrorsArray?.some(e => !!e)) {
            const slotsErrorsObject = { slots: slotsErrorsArray };
            if (intentsErrorsObject[intentKey]) {
                intentsErrorsObject[intentKey] = { ...intentsErrorsObject[intentKey], ...slotsErrorsObject };
            }
            else {
                intentsErrorsObject[intentKey] = slotsErrorsObject;
            }
        }

    }

    if (intentsErrorsObject && Object.keys(intentsErrorsObject).length > 0) {
        errors = { interactionModel: intentsErrorsObject };
    }

    // Returned errors object must be empty for validation to succeed
    return errors;
}

const getNlpEntitiesInLocales = (nlpEntities: EditableNlpEntity[], locales: string[]): EditableNlpEntity[] => {
    // Filter for selected locales 
    const formEntities = nlpEntities?.filter(nlpEntity => locales.every(shortCode => nlpEntity.locales.includes(shortCode)));
    return formEntities;
};

const validateSlotNlpEntityName = (slot: EditableSlot, entities: EditableNlpEntity[]): boolean => {
    const entityName = slot?.entityName;
    const found = entities?.find(e => e.name == entityName);
    return !!found;
}

const validateNlpEntityRegexPattern = (value: string): string => {
    if (!value || value.trim() == "") {
        return "Regex Pattern is required";
    }

    try {
        // this is the same code that the nlp api uses to generate RegEx's from a string
        // lets try it here and if it fails then we know it's not going to work
        // when training or being used in the nlp api. 
        let regexString = value.slice();
        let flag = "";
        let index = value.lastIndexOf('/');
        if (index !== -1) {
            flag = regexString.slice(index + 1);
            regexString = regexString.slice(1, index);
        }
        const regex = new RegExp(regexString, flag);
    } catch (e) {
        return `Invalid regular expression: ${value}`;
    }

    return null;
};


const validateNlpEntitiesFormFields = (values: InteractionModelFormData): FormikErrors<InteractionModelFormData> => {
    // Validate that List type NLP Entity's Values array is not null, empty list is allowed, and that value's names are unique
    let errors: FormikErrors<InteractionModelFormData> = {};
    let entitiesErrorsArray: FormikErrors<EditableNlpEntity>[] = [];

    for (let entityIdx = 0; entityIdx < values?.nlpEntities?.length; entityIdx++) {
        const entity = values?.nlpEntities[entityIdx];
        let nlpValuesErrorsArray: any[] = [];
        // Initialize null error for correct Form values array index
        entitiesErrorsArray.push(null); // need to keep exact index to match between errors and entity form fields
        // No need to validate deleted entity
        if (entity.isDeleted) continue;

        const duplicateEntityNames = values?.nlpEntities?.filter((e, idx) =>
            idx != entityIdx
            && !e.isDeleted
            && e.name.trim().toLowerCase() == entity.name.trim().toLowerCase());

        if (duplicateEntityNames?.length > 0) {
            const error = `Duplicate NLP entity name: "${entity.name}" `;
            // Form's field's "name" error will show up in FieldError
            entitiesErrorsArray[entityIdx] = { name: error };
            errors = { nlpEntities: entitiesErrorsArray };
        }

        // Large entities values validation can significabtly slow down performance, so only validating non Large entity values
        if (entity.isLarge || !entity.isModified) continue;

        // Newly added entity does not have isLarge populated in time after importing large volume of values, so need to recheck
        if (entity.values?.length > LARGE_ENTITY_VALUES_COUNT) continue;

        if (entity.type === "List") {
            // Check for duplicate value names
            const nlpValues = entity.values;
            for (let valueIdx = 0; valueIdx < nlpValues?.length; valueIdx++) {
                const nlpValue = nlpValues[valueIdx];
                nlpValuesErrorsArray.push(null);

                if (!nlpValue?.name) {
                    const error = `Entity value name is required `;
                    // Form's field's "name" error will show up in FieldError
                    nlpValuesErrorsArray[valueIdx] = { name: error };
                }
            }
        }
        else if (entity.type === "Regex") {
            // Validate value pattern regular expression
            const nlpValues = entity.values;
            for (let valueIdx = 0; valueIdx < nlpValues?.length; valueIdx++) {
                const nlpValue = nlpValues[valueIdx];
                nlpValuesErrorsArray.push(null);
                const error = validateNlpEntityRegexPattern(nlpValue?.pattern);
                if (error) {
                    nlpValuesErrorsArray[valueIdx] = { pattern: error };
                }
            }
        }

        if (nlpValuesErrorsArray?.length > 0 && nlpValuesErrorsArray?.some(e => !!e)) {
            const valuesErrorsObject = { values: nlpValuesErrorsArray };

            if (entitiesErrorsArray[entityIdx]) {
                entitiesErrorsArray[entityIdx] = { ...entitiesErrorsArray[entityIdx], ...valuesErrorsObject }
            }
            else {
                entitiesErrorsArray[entityIdx] = valuesErrorsObject;
            }
        }
    }

    if (entitiesErrorsArray?.length > 0 && entitiesErrorsArray?.some(e => !!e)) {
        errors = { nlpEntities: entitiesErrorsArray };
    }

    // Returned errors object must be empty for validation to succeed
    return errors;
}

export const sortEditableInteractionModel = (mergedInteractionModel: EditableInteractionModel): EditableInteractionModel => {
    let editableIntents: EditableIntent[] = Object.keys(mergedInteractionModel).map(intentKey => mergedInteractionModel[intentKey]);
    const editableInteractionModel: EditableInteractionModel = sortEditableIntents(editableIntents);
    return editableInteractionModel;
}

export const findIntentsByName = (mergedInteractionModel: EditableInteractionModel, name: string): EditableIntent[] => {
    let editableIntents: EditableIntent[] = Object.keys(mergedInteractionModel).map(intentKey => mergedInteractionModel[intentKey]);
    const intents: EditableIntent[] = editableIntents?.filter(i => i.displayName == name);

    return intents;
}

const createEditableIntent = (intent: Intent, interactionModel: InteractionModel, language: LanguageModel): EditableIntent => {
    const disabledIntents = interactionModel.disabledIntents ?? [];

    const utterances = intent.utterances?.map(u => {
        return {
            phrase: u,
            changeType: "Original",
            language
        } as Utterance
    });

    const editableSlots = intent.slots?.map(slot => {
        return {
            ...slot,
            isAdded: false,
            isDeleted: false,
            isModified: false,
            isOriginal: true,
            originalName: slot.name
        } as EditableSlot
    });

    const key = intent.displayName + "|" + language?.shortCode;

    const intentToEdit: EditableIntent = {
        ...intent,
        key: key,
        originalDisplayName: intent.displayName,
        displayName: intent.displayName,
        disabledForFeatureFlagIds: intent.disabledForFeatureFlagIds,
        utterances: utterances,
        slots: editableSlots,
        isDisabled: disabledIntents.find(di => di.displayName === intent.displayName) != null,
        updatedDisabledState: false,
        isCustom: intent.isCustom,
        modifiedDate: intent.modifiedDate,
        locales: [{ shortCode: language?.shortCode, intentId: intent.id }],
        isDeleted: false,
        isAdded: false,
        isModified: false,
        name: intent.name,
        requiresExactUtteranceMatch: intent.requiresExactUtteranceMatch,
    };

    return intentToEdit;
}

const sortEditableIntents = (editableIntents: EditableIntent[]): EditableInteractionModel => {
    // Custom Intents first, hacky sort, was not able to sort by two fields, isCustom first name second :()
    let editableIntentsOrdered: EditableIntent[] = _.orderBy(editableIntents ?? [], (r) => (r.isCustom ? "AAA" : "ZZZ") + r.displayName, "asc");

    const editableInteractionModel = {} as EditableInteractionModel
    editableIntentsOrdered.forEach(intent => {
        editableInteractionModel[intent.key] = intent;
    });
    return editableInteractionModel;
}

const createEditableLocaleInteractionModel = (interactionModel: InteractionModel, language: LanguageModel): EditableInteractionModel => {
    const editableIntents: EditableIntent[] = interactionModel.intents
        .filter(intent => intent.isUserEditable !== false) // null is assumed true - needs explicit false
        .map(intent => createEditableIntent(intent, interactionModel, language));

    const editableInteractionModel: EditableInteractionModel = sortEditableIntents(editableIntents);
    return editableInteractionModel;
}

const checkSlotsTheSame = (intent1: EditableIntent, intent2: Intent): boolean => {
    const intent1Slots = intent1?.slots?.map(s => {
        const slot: Slot = {
            name: s.name,
            required: s.required,
            entityId: s.entityId ?? "",
            entityName: s.entityName ?? ""
        }
        return slot;
    });
    const intent2Slots = intent2?.slots ?? [];
    const same: boolean = (intent1Slots?.length == intent2Slots?.length) && intent2Slots?.every(s => {
        return intent1Slots?.find(original => original.name == s.name && original.required == s.required && original.entityId == s.entityId && original.entityName == s.entityName)
    });

    return same;
}

export const buildEditableInteractionModel = (interactionModels: InteractionModel[], languages: LanguageModel[]): EditableInteractionModel => {
    let mergedModel: EditableInteractionModel;

    for (let i = 0; i < interactionModels.length; i++) {
        const localeInteractionModel: InteractionModel = interactionModels[i];
        const language = languages?.find(l => l?.shortCode == localeInteractionModel.locale);
        if (language == null) {
            // No need to get this interaction model, it is not in selected languages
            // The good question is why did it came in props.interactionModels at all ????
            continue;
        }

        if (!mergedModel) {
            // Create editable interaction model to display in UI, first language in a loop
            // Intent in different locales can have different set of slots and utterances
            mergedModel = createEditableLocaleInteractionModel(localeInteractionModel, language)
        } else {
            // start adding language/locale utterances to existing editable intents and add/merge new intents that not yet in model
            mergedModel = mergeLocaleIntents(mergedModel, localeInteractionModel, language);
        }
    }
    return mergedModel;
}

const mergeLocaleIntents = (firstLocaleModel: EditableInteractionModel, localeInteractionModel: InteractionModel, language: LanguageModel): EditableInteractionModel => {
    if (localeInteractionModel == null || localeInteractionModel?.intents?.length == 0 || language == null) {
        return firstLocaleModel;
    }
    // start adding language/locale utterances to existing editable intents and add/merge new intents that not yet in model
    let mergedModel: EditableInteractionModel = { ...firstLocaleModel };

    localeInteractionModel.intents.forEach(intent => {
        let intentWasMerged: boolean = false;
        const sameNameIntents = findIntentsByName(mergedModel, intent.displayName);

        for (let i = 0; i < sameNameIntents?.length; i++) {
            // Intent with that display name already exists
            let mergedIntent: EditableIntent = sameNameIntents[i];
            const sameSlots = checkSlotsTheSame(mergedIntent, intent);

            if (!mergedIntent.isCustom || sameSlots) {
                // Add locale's specific utterances to existing intent
                mergedIntent.utterances.push(...intent.utterances
                    .map(u => (
                        {
                            phrase: u,
                            changeType: 'Original' as UtteranceType,
                            language
                        } as Utterance
                    )));

                // Keep track in wich locales this intent exists
                mergedIntent.locales.push({ shortCode: language?.shortCode, intentId: intent.id });
                // Change the key, append locale
                const intentNewKey = mergedIntent.key + "|" + language?.shortCode;
                delete mergedModel[mergedIntent.key];
                mergedIntent.key = intentNewKey;
                mergedModel[intentNewKey] = mergedIntent;

                intentWasMerged = true;
            }
        }

        // If locale's Custom intent not found in merged model or slots deviate create a new one
        if (!intentWasMerged && intent.isUserEditable && intent.isCustom) {
            const newCustomIntent: EditableIntent = createEditableIntent(intent, localeInteractionModel, language);
            mergedModel[newCustomIntent.key] = newCustomIntent;
        }
    });

    //Resort model by Intents Modified Date to correctly display newly added intents in UI
    mergedModel = sortEditableInteractionModel(mergedModel);
    return mergedModel;
}

export const addEditableIntentToUpdate = (update: InteractionModelUpdate, intentUpdate: IntentUpdate, intent: EditableIntent, language: LanguageModel): InteractionModelUpdate => {
    // Set Enable/Disable an intent update properties
    const intentId = intent.locales.find(l => l.shortCode === language.shortCode)?.intentId;
    if (intent.updatedDisabledState && intent.isDisabled) {
        update.disableIntents.push({ intentName: intent.originalDisplayName, intentId: intentId });
    }
    else if (intent.updatedDisabledState && !intent.isDisabled) {
        update.enableIntents.push({ intentName: intent.originalDisplayName, intentId: intentId });
    }

    let addedUtterances: string[] = [];
    let removedUtterances: string[] = [];
    // Get merged intent's modified Utterances in specific locale
    intent.utterances?.filter(u => u.changeType != "Original" && u.language.id == language.id)
        ?.map(u => {
            if (u.changeType == "Added") {
                addedUtterances.push(u.phrase);
            }
            else if (u.changeType == "Removed") {
                removedUtterances.push(u.phrase);
            }
        }
        )

    let addedSlots: Slot[] = [];
    let removedSlots: string[] = [];
    // Get merged added and removed intent's slots
    intent.slots?.map(slot => {
        if (!slot.isOriginal && slot.isAdded && !(slot.isDeleted)) {
            // Brand new slot added
            addedSlots.push(slot);
        }
        else if (slot.isOriginal && slot.isDeleted) {
            // Existing slot was deleted, could have been renamed as well, use original name
            removedSlots.push(slot.originalName);
        }
        else if (slot.isOriginal && slot.isModified) {
            // Remove and readd
            removedSlots.push(slot.originalName);
            addedSlots.push(slot);
        }
    })

    //Check if merged intent exists in this locale Interaction Model and do updates
    if (intentExistsInLocaleInteractionModel(intent, language)) {
        if (intent.isDeleted && !intent.isAdded) {
            // Set existing intent for removal from Interaction Model locale
            update.removeCustomIntents.push({ intentName: intent.originalDisplayName, intentId: intentId });
        }
        else if (intent.isAdded && !intent.isDeleted) {
            //If it is a brand new Custom intent then add it to update
            const newIntent: Intent = {
                ...intent,
                id: intent.displayName,
                displayName: intent.displayName,
                isUserEditable: true,
                isCustom: intent.isCustom,
                slots: intent.slots,
                utterances: addedUtterances,
                requiresExactUtteranceMatch: intent.requiresExactUtteranceMatch
            }

            update.addCustomIntents.push(newIntent);
        }
        else {
            // If an existing intent was modified then record it's update properties
            if (addedUtterances.length > 0) {
                intentUpdate.addedUtterances = addedUtterances;
            }
            if (removedUtterances.length > 0) {
                intentUpdate.removedUtterances = removedUtterances;
            }

            if (addedSlots.length > 0) {
                intentUpdate.addedSlots = addedSlots;
            }
            if (removedSlots.length > 0) {
                intentUpdate.removedSlots = removedSlots;
            }

            if (!!intent.originalDisplayName && intent.originalDisplayName != intent.displayName) {
                intentUpdate.updatedDisplayName = intent.displayName;
            }

            intentUpdate.requiresExactUtteranceMatch = intent.requiresExactUtteranceMatch;


            const updateExistingIntent: boolean = intentUpdate.addedUtterances.length > 0
                || intentUpdate.removedUtterances.length > 0
                || intentUpdate.addedSlots.length > 0
                || intentUpdate.removedSlots.length > 0
                || !!intentUpdate.updatedDisplayName
                || intent.didUpdateRequiresExactMatch
                || intent.isModified;

            intentUpdate.disabledForFeatureFlagIds = intent.disabledForFeatureFlagIds;

            if (updateExistingIntent) {
                // Update existing intent properties
                update.intents[intent.isCustom ? intentId : intent.originalDisplayName] = intentUpdate;
            }
        }
    }
    else {
        // Merged intent does not exist in specific locale
        if (!intent.isDeleted && (addedUtterances.length > 0 || intent.isAdded)) {
            // 1. addedUtterances.length > 0 If we assigned any utterances to this locale we need to add new intent to Interaction Model locale
            // 2. isAdded - brand new intent, might have no utterances yet
            const newIntent: Intent = {
                ...intent,
                id: intent.displayName,
                displayName: intent.displayName,
                isUserEditable: true,
                isCustom: intent.isCustom,
                slots: intent.slots,
                utterances: addedUtterances,
                requiresExactUtteranceMatch: intent.requiresExactUtteranceMatch
            }

            update.addCustomIntents.push(newIntent);
        }
    }
    return update;
}

export const convertToInteractionModelLocaleUpdate = (values: InteractionModelFormData, language: LanguageModel): InteractionModelUpdate => {
    let update = {
        intents: {},
        locale: language?.shortCode,
        disableIntents: [],
        enableIntents: [],
        removeCustomIntents: [],
        addCustomIntents: []
    } as InteractionModelUpdate;
    var mergedInteractionModel = values.interactionModel;
    Object.keys(mergedInteractionModel)?.map(displayName => {
        let mergedIntent = mergedInteractionModel[displayName];
        let intentUpdate = {
            addedUtterances: [],
            removedUtterances: [],
            addedSlots: [],
            removedSlots: [],
            updatedDisplayName: "",
            requiresExactUtteranceMatch: false
        } as IntentUpdate;

        if (mergedIntent.isModified) {
            intentUpdate.allowEnhancedIntentMatching = mergedIntent.allowEnhancedIntentMatching;
            intentUpdate.enhancedMatchingDescription = mergedIntent.enhancedMatchingDescription;
            intentUpdate.alwaysIncludeInPrompt = mergedIntent.alwaysIncludeInPrompt;
        }

        update = addEditableIntentToUpdate(update, intentUpdate, mergedIntent, language);
    });
    let enableEnhancedIntentMatching = undefined;
    let updatedPrompt = undefined;
    if (values.disableEnhancedIntentMatching) {
        enableEnhancedIntentMatching = false;
    } else if (values.enableEnhancedIntentMatching) {
        enableEnhancedIntentMatching = true;
    }
    if (values.updatedEnhancedIntentMatchingPrompt?.length) {
        updatedPrompt = values.updatedEnhancedIntentMatchingPrompt;
    }
    update.updatedEnhancedIntentMatchingPrompt = updatedPrompt;
    update.enableEnhancedIntentMatching = enableEnhancedIntentMatching;

    //No updates
    if (isEmptyInteractionModelUpdate(update)) return null;

    return update;
}

export const isEmptyInteractionModelUpdate = (update: InteractionModelUpdate): boolean => {
    return Object.keys(update.intents).length == 0
        && update.disableIntents.length == 0
        && update.enableIntents.length == 0
        && update.removeCustomIntents.length == 0
        && update.addCustomIntents.length == 0
        && (update.enableEnhancedIntentMatching === undefined || update.enableEnhancedIntentMatching === null)
        && !update?.updatedEnhancedIntentMatchingPrompt?.length;
}

const intentExistsInLocaleInteractionModel = (intent: EditableIntent, language: LanguageModel): boolean => {
    const localeMatch = intent.locales.filter(l => l.shortCode == language?.shortCode);
    return (localeMatch?.length > 0)
}

export const convertValuesToCSVText = (values: NlpEntityValueModel[]): string => {
    if (!values || values?.length == 0) return "";

    const newLine = '\r\n';
    let textBoxValues = "";
    const arrayOfLines = values.map(v => convertValueToCSVLine(v))
    textBoxValues = arrayOfLines.join(newLine);
    return textBoxValues;
};

export const convertCSVtextToValues = (textBoxValues: string): NlpEntityValueModel[] => {
    const parsed = CSVtoArray(textBoxValues);
    const values: NlpEntityValueModel[] = parsed?.filter(p => {
        if (!p?.length || !p[0]) // ignore empty values and values without names
            return false;
        return true;
    })?.map(p => {
        let value: NlpEntityValueModel = {};
        // First element of the array is value name
        value.name = p[0];
        // The rest of array elements are synonyms with the last potentially being metadata
        if (p.length > 1) {
            if (p[p.length - 1].startsWith("metadata:")) {
                value.metadata = JSON.parse(p[p.length - 1].replace("metadata:", ""));
                value.synonyms = [...p.slice(1, p.length - 1)];
            } else {
                value.synonyms = [...p.slice(1, p.length)];
            }
        }

        return value;
    })

    return values;
};

const convertValueToCSVLine = (entityValue: NlpEntityValueModel): string => {
    if (entityValue == null || !entityValue.name) return "";

    const comma = ",";
    const valueName = checkAndWrapForCSV(entityValue.name);
    const synonyms: string = entityValue.synonyms?.map(s => checkAndWrapForCSV(s))?.join(comma);
    let line: string = valueName;
    if (synonyms?.length > 0) {
        line = `${valueName}${comma}${synonyms}`;
    }
    if (Object.keys(entityValue.metadata ?? {}).length) {
        let metadataString = JSON.stringify(entityValue.metadata);
        const searchRegExp = /"/g;
        const replaceWith = '""';
        metadataString = metadataString.replace(searchRegExp, replaceWith);
        line = `${line}${comma}"metadata:${metadataString}"`
    }
    return line;
};

const checkAndWrapForCSV = (inputText: string): string => {
    if (inputText == null) return "";

    let outputText = inputText.trim();
    if (outputText == "") return "";

    const comma = ",";
    const doubleQ = '"';

    if (outputText.includes(comma)) {
        // Wrap in quotes if it is not already
        if (!outputText.startsWith(doubleQ)) {
            outputText = `${doubleQ}${outputText}`;
        }
        if (!outputText.endsWith(doubleQ)) {
            outputText = `${outputText}${doubleQ}`;
        }
    }

    return outputText;
};

//using PapaParse https://github.com/mholt/PapaParse
function CSVtoArray(text): string[][] {
    var parseResult = Papa.parse(text);
    return parseResult.data;
};

export const extractSlots = (phrase: string): PhraseSlot[] => {
    const regex = /{[^}]*}/g;
    const slotsExtracted = [] as PhraseSlot[];
    let match;

    while ((match = regex.exec(phrase)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (match.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        match.forEach((match: string) => {
            slotsExtracted.push(new PhraseSlot(match.trim()))
        });
    }
    return slotsExtracted;
};

export default InteractionModelForm;