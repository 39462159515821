import React from 'react';
import { Subscribe } from 'unstated';
import AnalyticsInterval from '../../models/analytics/AnalyticsInterval';
import moment from 'moment';
import { getEditUrl, getFeatureContentUrl } from '../../models/extensions';
import ContentInteractionAnalyticsContainer from '../../state/containers/ContentInteractionAnalyticsContainer';
import ContentUserAnalyticsContainer from '../../state/containers/ContentUserAnalyticsContainer';
import ContentStatisticsContainer from '../../state/containers/ContentStatisticsContainer';
import TabContainerPanel from '../../components/structure/Panels/TabContainerPanel';
import TimeIntervalSelectorHeader from '../../components/analytics/TimeIntervalSelectorHeader';
import { TabPanel } from 'react-tabs';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import DateRangeIntervalPicker from '../../components/analytics/DateRangeIntervalPicker';
import ContentMeasurementPage from './components/ContentMeasurementPage';
import { css } from '@emotion/css';
import { color_shades_dark } from '../../constants/colors';
import { DATE_FORMAT } from '../../models/analytics/AnalyticsDateFormat';
import ApplicationAnalyticsFacetContainer from '../../state/containers/ApplicationAnalyticsFacetContainer';
import AnalyticsFilterState from '../../models/analytics/AnalyticsFilterState';
import FilterToggleButton from '../../components/analytics/filters/FilterToggleButton';
import AnalyticsFilters from '../../components/analytics/filters/AnalyticsFilters';
import ContentItemModel from '../../models/features/api/ContentItemModel';
import ContentTrackingPage from './components/ContentTrackingPage';
import qs from 'query-string';
import ContentTrackingContainer from '../../state/containers/ContentTrackingContainer';
const measureIcon = require('../../content/images/analytics-icons/measure-icon.svg');
const measureIconBlue = require('../../content/images/analytics-icons/measure-icon-blue.svg');
const trackIcon = require('../../content/images/analytics-icons/track-icon.svg');
const trackIconBlue = require('../../content/images/analytics-icons/track-icon-blue.svg');

interface ContentAnalyticsProps {
    applicationId: string
    applicationFeatureId: string
    contentItem: ContentItemModel
    featureTypeId: string
    contentId: string
    facetContainer: ApplicationAnalyticsFacetContainer
    history: any
}
interface ContentAnalyticsPageState {
    interval: AnalyticsInterval
    startDate: string
    endDate: string
    isDatePickerExpanded: boolean
    isFilterExpanded: boolean
    filter: AnalyticsFilterState
}
class ContentAnalytics extends React.Component<ContentAnalyticsProps, ContentAnalyticsPageState> {
    constructor(props) {
        super(props)
        const query = qs.parse(window.location.search);
        this.state = {
            interval: query?.interval as string ?? 'past month',
            startDate: query?.startDate as string ?? moment().startOf('day').subtract(28, 'days').format(DATE_FORMAT),
            endDate: query?.endDate as string ?? moment().endOf('day').add(2, 'days').format(DATE_FORMAT),
            isDatePickerExpanded: false,
            isFilterExpanded: false,
            filter: {}
        }
    }
    handleTabSelection(index: number, last: number) {
        if (index == last) return;

        const urlStart = getEditUrl(this.props.contentId, this.props.applicationFeatureId, this.props.featureTypeId) + "analytics/";

        if (index == 0) {
            this.props.history.push(urlStart)
        }
        if (index == 1) {
            this.props.history.push(urlStart + "track")
        }
    }
    getSelectedIndex() {
        if (location.href.indexOf("track") > -1) {
            return 1;
        }

        return 0;
    }
    handleDateRangeChange(startDate, endDate) {
        this.setState({
            ...this.state,
            startDate,
            endDate,
            isDatePickerExpanded: false
        });
    }
    toggleDatePicker() {
        this.setState({
            ...this.state,
            isDatePickerExpanded: !this.state.isDatePickerExpanded
        })
    }
    toggleFilter() {
        this.setState({
            ...this.state,
            isFilterExpanded: !this.state.isFilterExpanded
        })
    }
    handleFilterChange(filter: AnalyticsFilterState) {
        this.setState({
            ...this.state,
            filter
        });
    }
    getFilterCount() {
        let count = 0;
        if (this.state.filter?.assistants?.length > 0) {
            count++;
        }
        if (this.state.filter?.interfaces?.length > 0) {
            count++;
        }
        if (this.state.filter?.deviceTargetIds?.length > 0) {
            count++;
        }
        if (this.state.filter?.languageIds?.length > 0) {
            count++;
        }
        return count;
    }
    render() {
        const filter = this.props.facetContainer.convertFilter(this.state.startDate, this.state.endDate, this.state.filter, this.props.facetContainer.state.deviceTargets ?? [])
        return (
            <Subscribe to={[ContentUserAnalyticsContainer, ContentInteractionAnalyticsContainer, ContentStatisticsContainer, ContentTrackingContainer]}>
                {(userAnalyticsContainer: ContentUserAnalyticsContainer,
                    interactionAnalyticsContainer: ContentInteractionAnalyticsContainer,
                    statisticsContainer: ContentStatisticsContainer,
                    trackingContainer: ContentTrackingContainer) => {
                    return (
                        <TabContainerPanel
                            className={containerStyle}
                            tabs={[{
                                title: 'Measure',
                                icon: measureIcon,
                                selectedIcon: measureIconBlue
                            }, {
                                title: 'Track',
                                icon: trackIcon,
                                selectedIcon: trackIconBlue
                            }]}
                            selectedIndex={this.getSelectedIndex()}
                            onTabSelected={this.handleTabSelection.bind(this)}
                            additionalHeaderContent={() => <div className={headerContainer}>
                                <TimeIntervalSelectorHeader startDate={this.state.startDate} endDate={this.state.endDate} isExpanded={this.state.isDatePickerExpanded} onClick={this.toggleDatePicker.bind(this)} />
                                <FilterToggleButton filterCount={this.getFilterCount()} isExpanded={this.state.isFilterExpanded} onClick={this.toggleFilter.bind(this)} />
                            </div>}>
                            <TabPanel>
                                <ScrollablePanelBody>
                                    {this.state.isFilterExpanded &&
                                        <AnalyticsFilters currentFilter={this.state.filter}
                                            onChange={this.handleFilterChange.bind(this)}
                                            filterCount={this.getFilterCount()}
                                            languages={this.props.contentItem?.languages ?? []}
                                            deviceTargets={this.props.facetContainer.state.deviceTargets ?? []}
                                            assistants={this.props.facetContainer.state.assistants ?? []}
                                            interfaces={this.props.facetContainer.state.interfaces ?? []} />}
                                    {this.state.isDatePickerExpanded &&
                                        <DateRangeIntervalPicker startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            interval={this.state.interval}
                                            onChange={this.handleDateRangeChange.bind(this)}
                                            onClose={this.toggleDatePicker.bind(this)}
                                        />
                                    }
                                    <ContentMeasurementPage
                                        interval={this.state.interval}
                                        userAnalyticsContainer={userAnalyticsContainer}
                                        interactionAnalyticsContainer={interactionAnalyticsContainer}
                                        statisticsContainer={statisticsContainer}
                                        applicationId={this.props.applicationId}
                                        contentId={this.props.contentId}
                                        featureTypeId={this.props.featureTypeId}
                                        applicationFeatureId={this.props.applicationFeatureId}
                                        filter={filter}
                                        history={this.props.history} />
                                </ScrollablePanelBody>
                            </TabPanel>
                            <TabPanel>
                                {this.state.isFilterExpanded &&
                                    <AnalyticsFilters currentFilter={this.state.filter}
                                        onChange={this.handleFilterChange.bind(this)}
                                        filterCount={this.getFilterCount()}
                                        languages={this.props.contentItem?.languages ?? []}
                                        deviceTargets={this.props.facetContainer.state.deviceTargets ?? []}
                                        assistants={this.props.facetContainer.state.assistants ?? []}
                                        interfaces={this.props.facetContainer.state.interfaces ?? []} />}
                                {this.state.isDatePickerExpanded &&
                                    <DateRangeIntervalPicker startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        interval={this.state.interval}
                                        onChange={this.handleDateRangeChange.bind(this)}
                                        onClose={this.toggleDatePicker.bind(this)}
                                    />}
                                <ContentTrackingPage
                                    trackingContainer={trackingContainer}
                                    applicationId={this.props.applicationId}
                                    contentId={this.props.contentId}
                                    contentItem={this.props.contentItem}
                                    featureTypeId={this.props.featureTypeId}
                                    filter={filter}
                                    history={this.props.history} />
                            </TabPanel>
                        </TabContainerPanel>
                    )
                }}
            </Subscribe>
        )
    }
}
const containerStyle = css`
    .panel-tab-list {
        flex: 0;
    }
`
const headerContainer = css`
    display: flex;
    flex: 1;
    height: 100%;
    margin-left: 94px;
    margin-right: 32px;
    align-items: center;
    border-left: 1px solid ${color_shades_dark};
`

export default ContentAnalytics;