import { SimulatorMessage } from "./SimulatorInteraction";
import CustomAssistantResponse from "../../../models/customAssistant/CustomAssistantResponse";
import { css } from '@emotion/css';
import { color_shades_lighter } from "../../../constants/colors";
import React, { useEffect, useRef } from "react";
import LanguageModel from "../../../models/features/api/LanguageModel";
import SsmlContainer from "../../../state/containers/SsmlContainer";
import DeviceTargetModel from "../../../models/features/api/MediaResponses/DeviceTargetModel";
import { InterfaceType } from "./DeviceAndEnvironmentPicker";
import UserVariationsBubble from "../../../components/messagePreviews/UserVariationsBubble";
import ConversationRequest from "../../../models/conversationFlow/ConversationRequest";
import DeviceScreenVariationsBubble from "../../../components/messagePreviews/DeviceScreenVariationsBubble";
import ConversationMediaResponse from "../../../models/conversationFlow/ConversationMediaResponse";
import DeviceAudioVariationsBubble from "../../../components/messagePreviews/DeviceAudioVariationsBubble";
import DeviceChatVariationsBubble from "../../../components/messagePreviews/DeviceChatVariationBubble";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import DeviceLoadingChatBubble from "../../../components/messagePreviews/DeviceLoadingChatBubble";
import UnsupportedResponseBubble from "../../../components/messagePreviews/UnsupportedResponseBubble";
import UserApplicationPreferencesContainer from "../../../state/containers/UserApplicationPreferencesContainer";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import Button from "../../../components/general/Button";
import styled from "@emotion/styled";

const addThumbtackIcon = require('../../../content/images/simulator/add-thumbtack.svg');
const redoIcon = require('../../../content/images/simulator/redo.svg');
const deviceIcon = require('../../../content/images/device.svg');

interface SimulatorConversationViewerProps {
    ssmlContainer: SsmlContainer
    applicationId: string
    requests: SimulatorMessage[]
    responses: CustomAssistantResponse[]
    selectedLanguage?: LanguageModel
    viewOnly?: boolean
    selectedDevice?: DeviceTargetModel
    userApplicationPreferencesContainer: UserApplicationPreferencesContainer
    handleSendMessage: (text: string) => void
};

const SimulatorConversationViewer = (props: SimulatorConversationViewerProps) => {
    const scrollingRef = useRef(null);
    const [copyButtonText, setCopyButtonText] = React.useState<string>("Copy Transcript");

    useEffect(() => {
        if (copyButtonText === "Copied!") {
            setCopyButtonText("Copy Transcript");
        }
    }, [props.responses, props.applicationId, props.requests])
    useEffect(() => {
        scrollingRef.current?.scrollIntoView({
            block: "end"
        })
    })

    const renderConversationTurn = (message: SimulatorMessage, interfaceType: InterfaceType, index: number) => {
        const deviceResponse = props.responses[index];
        const variation: ConversationRequest = { content: message.text, id: index.toString(), highlights: [] };
        const mappedResponse = mapCustomAssistantResponseToMediaResponse(deviceResponse);
        return (
            <div key={index} className="conversation-turn">
                <div className="user-variation">
                    <UserVariationsBubble bubbleWidthPercentage={60} className="user-bubble" variations={[variation]}></UserVariationsBubble>
                    {props.viewOnly ?
                        null :
                        <div className="icons">
                            <TooltipWrapper text="Pin this message to use later">
                                <img className="pin" src={addThumbtackIcon}
                                    onClick={() => props.userApplicationPreferencesContainer.addSimulatorPinnedMessage(props.applicationId, message.text)} />
                            </TooltipWrapper>
                            <TooltipWrapper text="Send this message again">
                                <img className="redo" src={redoIcon}
                                    onClick={() => props.handleSendMessage(message.text)} />
                            </TooltipWrapper>

                        </div>
                    }
                </div>
                {deviceResponse ?
                    (mappedResponse.isUnsupportedResponse ? <UnsupportedResponseBubble /> :
                        interfaceType == "chat" ? <DeviceChatVariationsBubble
                            deviceTargetId={props.selectedDevice.id}
                            variations={[mappedResponse]} /> :
                            interfaceType == "audio" ? <DeviceAudioVariationsBubble
                                ssmlContainer={props.ssmlContainer}
                                assistant={props.selectedDevice.assistant}
                                deviceTargetId={props.selectedDevice.id}
                                variations={[mappedResponse]}
                                languages={[props.selectedLanguage]} /> :
                                interfaceType == "screen" ? <DeviceScreenVariationsBubble
                                    ssmlContainer={props.ssmlContainer}
                                    deviceTarget={props.selectedDevice}
                                    variations={[mappedResponse]}
                                    languages={[props.selectedLanguage]} /> : null)
                    :
                    <DeviceLoadingChatBubble icon={deviceIcon} />
                }
            </div>
        )
    }

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const copyTranscript = () => {
        var transcript = "";
        props.requests.forEach((r, i) => {
            const deviceResponse = props.responses[i];
            if (r?.text?.length) {
                transcript += `User: ${r.text}\n\n`;
            }
            if (deviceResponse?.displayText?.length || deviceResponse?.ssml?.length) {
                transcript += `Assistant: ${deviceResponse?.displayText ?? deviceResponse.ssml}\n\n`;
            }
        });
        copyText(transcript);
    }

    const mapCustomAssistantResponseToMediaResponse = (deviceResponse: CustomAssistantResponse) => {
        if (!deviceResponse) return null;

        const isUnsupported = deviceResponse.ssml == null
            && deviceResponse.audioFile == null
            && deviceResponse.videoFile == null
            && deviceResponse.displayText == null
            && deviceResponse.displayTitle == null;

        return {
            audioItem: deviceResponse.audioFile,
            videoItem: deviceResponse.videoFile,
            foregroundImageItem: deviceResponse.foregroundImage,
            backgroundImageItem: deviceResponse.backgroundImage,
            deviceTarget: props.selectedDevice,
            id: deviceResponse.responseId,
            responseTemplateTypeId: deviceResponse.responseTemplate,
            applicationId: props.applicationId,
            displayText: deviceResponse.displayText ?? "",
            displayTitle: deviceResponse.displayTitle ?? "",
            content: deviceResponse.ssml,
            highlights: [],
            imageItem: deviceResponse.foregroundImage,
            isUnsupportedResponse: isUnsupported
        } as ConversationMediaResponse
    };
    if (!props.selectedLanguage && !props.viewOnly)
        return (<div className={containerStyle}><div className="no-lang">You must add at least one language to your application first.</div></div>)

    const hasMessages = props.requests?.length > 0;
    if ((!hasMessages || !props.selectedDevice) && !props.viewOnly)
        return (<div className={containerStyle}></div>)

    const device = props.selectedDevice;
    const chat = !device.supportsSsml && device.supportsDisplayText;
    const audio = device.supportsSsml && !device.supportsDisplayText;
    const interfaceType: InterfaceType = chat ? "chat" : audio ? "audio" : "screen";
    return (
        <div className={containerStyle}>
            <CopyButton
                themes={['secondary-small']}
                text={copyButtonText}
                onClick={() => { copyTranscript(); setCopyButtonText("Copied!"); }}
                type="button"
            />
            {
                props.viewOnly ?
                    <>
                        {props.requests.map((c, i) => {
                            return renderConversationTurn(c, interfaceType, i);
                        })}
                        <div className="end-padding" key="end" ref={scrollingRef} />
                    </>
                    :
                    <CustomScrollbars>
                        {props.requests.map((c, i) => {
                            return renderConversationTurn(c, interfaceType, i);
                        })}
                        <div className="end-padding" key="end" ref={scrollingRef} />
                    </CustomScrollbars>

            }
        </div>
    )
};

const CopyButton = styled(Button)`
    width: 186px;
    position: absolute;
    top: 24px;
    right: 24px;
    min-width: auto;
    width: auto;
    z-index: 100;
`;

const containerStyle = css`
    background: ${color_shades_lighter};
    overflow: auto;
    display:flex;
    flex-grow:1;
    flex-direction:row;
    flex-wrap: wrap;
    position: relative;
    max-width:100%;
    >* {
        min-width:100%;
        max-width:100%;
    }
    .no-lang {
        padding-left: 20%;
        align-self:center;
    }
    .end-padding {
        padding-bottom: 32px;
    }
    .conversation-turn {
        .user-variation {
            display: flex;
            flex-direction: row;   
            position: relative;              
        }
        .user-bubble {
            width: 100%;
        }
    }
    .icons {
        img {
            position: absolute;
            cursor: pointer;
            &.pin {
                top: 30px;
                left: calc(60% + 60px);
            }
            &.redo {
                top: 62px;
                left: calc(60% + 60px);
            }
        }
    } 
`;

export default SimulatorConversationViewer;
