import styled from "@emotion/styled";
import { FormikProps } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Loader from "../../../components/general/Loader";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import { color_shades_dark } from "../../../constants/colors";
import useWindowSize from "../../../hooks/UseWindowSize";
import { InteractionModelFormData } from "./InteractionModelForm";
import EmptyView from '../../featureContent/components/EmptyView';
import VoicifyMenuItems from "./VoicifyMenuItems";
import VoicifyMenuEditor from "./VoicifyMenuEditor";
import MenuItemModel from "../../../models/nlp/menu/MenuItemModel";
import { v4 as uuidv4 } from 'uuid';
import { MenuItemOptionGroup } from "../../../models/nlp/menu/MenuItemOptionGroup";
import { MenuItemSizeGroup } from "../../../models/nlp/menu/MenuItemSizeGroup";
import EditedMenuItemModel from "../../../models/nlp/menu/EditedMenuItemModel";
import VoicifyMenuHeader from "./VoicifyMenuHeader";
import ImportFileDialog from "../../../components/general/ImportFileDialog";

export type NlpEntityCreateMethod = "Custom" | "Prebuilt";

interface VoicifyMenuItemsEditorProps {
    isLoading: boolean;
    applicationId: string;
    updatedMenuItems: EditedMenuItemModel[];
    setUpdatedMenuItems: Dispatch<SetStateAction<EditedMenuItemModel[]>>
    selectedMenuItem: EditedMenuItemModel;
    setSelectedMenuItem: Dispatch<SetStateAction<EditedMenuItemModel>>
    menuItems: MenuItemModel[];
    appName: string;
};

const VoicifyMenuItemsEditor: React.FC<VoicifyMenuItemsEditorProps & FormikProps<InteractionModelFormData>> = (props) => {
    const [activeItemIndex, setActiveItemIndex] = useState(-1);
    const [importFileModalOpen, setImportFileModalOpen] = useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (!props.updatedMenuItems?.length)
            props.setSelectedMenuItem(null);
    }, [props.updatedMenuItems])

    const formatFileName = () => {
        const words = props.appName ? props.appName.toLowerCase().split(/\s+/) : [];
        const date = new Date();
        const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
        words.push(formattedDate);
        words.push(formattedTime);
        const formattedName = words.join('-');
        return formattedName;
    };

    const toggleItemActivation = (editedMenuItem: EditedMenuItemModel, index: number) => {
        setActiveItemIndex(index);
        const newSelectedMenuItem: EditedMenuItemModel[] = props.updatedMenuItems.filter((menuItem) => {
            return (menuItem.menuItem.id === editedMenuItem.menuItem.id) && (menuItem.menuItem.comboId === editedMenuItem.menuItem.comboId);
        });
        props.setSelectedMenuItem(newSelectedMenuItem[0]);
    };

    const processMenuImport = (file: File) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async (e) => {
            const result = e.target.result;
            const importedMenuItems = JSON.parse(result as string);

            const editedMenuItems: EditedMenuItemModel[] = importedMenuItems.map((importedMenuItem: MenuItemModel) => {
                const isAMatch = props.updatedMenuItems.some((existingMenuItem) => existingMenuItem.menuItem.id === importedMenuItem.id);
                if (isAMatch) {
                    return { menuItem: { ...importedMenuItem, applicationId: props.applicationId }, isModified: true };
                } else {
                    return { menuItem: { ...importedMenuItem, applicationId: props.applicationId }, isAdded: true };
                }
            });

            const updatedMenuItems: EditedMenuItemModel[] = props.updatedMenuItems.map((existingMenuItem: EditedMenuItemModel, index: number) => {
                const isAMatch = editedMenuItems.some((editedMenuItem) => editedMenuItem.menuItem.id === existingMenuItem.menuItem.id);
                if (!isAMatch) {
                    return { menuItem: { ...existingMenuItem.menuItem, applicationId: props.applicationId }, isDeleted: true };
                } else {
                    existingMenuItem.menuItem.applicationId = props.applicationId;
                    return existingMenuItem;
                }
            }).concat(editedMenuItems.filter((editedMenuItem) => !props.updatedMenuItems.some((existingMenuItem) => existingMenuItem.menuItem.id === editedMenuItem.menuItem.id)));

            props.setUpdatedMenuItems(updatedMenuItems);
        };

        setImportFileModalOpen(false);
    }

    const handleImportMenuClick = () => {
        setImportFileModalOpen(true);
    };

    const handleExportMenuClick = () => {
        const menuStr = JSON.stringify(props.menuItems);
        const blob = new Blob([menuStr], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${formatFileName()}.json`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const handleAddItemClick = (isCopy?: boolean, menuItem?: MenuItemModel, index?: number) => {
        const copiedOptionGroups: MenuItemOptionGroup[] = menuItem?.optionGroups?.map(optionGroup => {
            return { ...optionGroup }
        });
        const copiedSizeGroups: MenuItemSizeGroup[] = menuItem?.sizeGroups?.map(sizeGroup => {
            return { ...sizeGroup }
        });
        // if menu item is passed in, it is a copy of an existing menu item
        const newMenuItem: EditedMenuItemModel = {
            menuItem: {
                applicationId: props.applicationId,
                id: uuidv4(),
                comboId: menuItem?.comboId ?? "",
                comboOnly: menuItem?.comboOnly ?? false,
                originalName: isCopy ? "Copy - " + menuItem?.originalName : "New Menu Item",
                colloquialName: menuItem?.colloquialName ?? "",
                optionGroups: isCopy ? copiedOptionGroups : [
                    {
                        referenceId: "",
                        name: "",
                        colloquialName: "",
                        numberOfRequiredOptions: null,
                        maxSelections: null,
                        limiter: "",
                        promptOverride: "",
                        initialPromptOverride: "",
                        numberedInitialPromptOverride: "",
                        automaticallyAddDefault: false,
                        isRequired: false,
                        options: [
                            {
                                id: "",
                                name: "",
                                colloquialName: "",
                                modifier: "",
                                default: false,
                                isPrimaryOption: false,
                                ignoreForReadout: false,
                                attributes: {}
                            }
                        ]
                    }
                ],
                sizeGroups: isCopy ? copiedSizeGroups : [
                    {
                        numberOfRequiredSizes: null,
                        promptOverride: "",
                        initialPromptOverride: "",
                        numberedInitialPromptOverride: "",
                        sizes: [
                            {
                                id: "",
                                name: "",
                                colloquialName: "",
                                default: false,
                                ignoreForReadout: false,
                                attributes: {},
                                optionGroups: [
                                    {
                                        referenceId: "",
                                        name: "",
                                        colloquialName: "",
                                        numberOfRequiredOptions: null,
                                        maxSelections: null,
                                        limiter: "",
                                        promptOverride: "",
                                        initialPromptOverride: "",
                                        numberedInitialPromptOverride: "",
                                        automaticallyAddDefault: false,
                                        isRequired: false,
                                        options: [
                                            {
                                                id: "",
                                                name: "",
                                                colloquialName: "",
                                                modifier: "",
                                                default: false,
                                                isPrimaryOption: false,
                                                ignoreForReadout: false,
                                                attributes: {}
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                attributes: {
                    description: menuItem?.attributes?.description ?? "",
                    calories: menuItem?.attributes?.calories ?? "",
                    servingSize: menuItem?.attributes?.servingSize ?? "",
                    ingredients: menuItem?.attributes?.ingredients ?? "",
                    allergenInfo: menuItem?.attributes?.allergenInfo ?? "",
                    data: menuItem?.attributes?.data ?? {}
                }
            },
            isAdded: true
        }

        const copyOfUpdatedMenuItems = [...props.updatedMenuItems];

        if (isCopy) {
            copyOfUpdatedMenuItems.splice(index, 0, newMenuItem);
        } else {
            copyOfUpdatedMenuItems.unshift(newMenuItem);
        }

        props.setUpdatedMenuItems(copyOfUpdatedMenuItems);
        props.setSelectedMenuItem(newMenuItem);
        setActiveItemIndex(index ?? 0);

    };

    const handleMenuItemChange = (value) => {
        const copyOfUpdatedMenuItems: EditedMenuItemModel[] = [...props.updatedMenuItems];
        const updatedMenuItemIndex = copyOfUpdatedMenuItems.findIndex((menuItem) => {
            return menuItem.menuItem.id === props.selectedMenuItem.menuItem.id
        });
        if (copyOfUpdatedMenuItems[updatedMenuItemIndex].isAdded) {
            copyOfUpdatedMenuItems[updatedMenuItemIndex] = { menuItem: { ...value }, isAdded: true };
        } else {
            copyOfUpdatedMenuItems[updatedMenuItemIndex] = { menuItem: { ...value }, isModified: true };
        }
        props.setUpdatedMenuItems(copyOfUpdatedMenuItems);
    };

    return (
        <>
            {props.isLoading ?
                <CustomLoader /> :
                <MenuWrapper>
                    {
                        importFileModalOpen &&
                        <ImportFileDialog
                            completeImport={processMenuImport}
                            onClose={() => setImportFileModalOpen(false)}
                            title={"Import your menu"}
                            acceptFileTypes={".json"}
                            acceptFileTypesDisplayText={"Supports .json"}
                            isLoading={props.isLoading}
                            errors={[]}
                            maxSizeOverrideMegabytes={100}
                        />
                    }
                    <CustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                        <Panel>
                            <VoicifyMenuHeader
                                showAddItemButton={true}
                                isLoading={props.isLoading}
                                handleAddItemClick={handleAddItemClick}
                                handleExportMenuClick={handleExportMenuClick}
                                handleImportMenuClick={handleImportMenuClick}
                            />
                            {(!props.updatedMenuItems?.length && !props.isLoading) &&
                                <EmptyViewPadding>
                                    <EmptyView subtext="Try adding a new menu item" />
                                </EmptyViewPadding>
                            }
                            {props.updatedMenuItems &&
                                <VoicifyMenuItems
                                    handleAddItemClick={handleAddItemClick}
                                    activeItemIndex={activeItemIndex}
                                    setActiveItemIndex={setActiveItemIndex}
                                    setSelectedMenuItem={props.setSelectedMenuItem}
                                    selectedMenuItem={props.selectedMenuItem}
                                    toggleItemActivation={toggleItemActivation}
                                    applicationId={props.applicationId}
                                    updatedMenuItems={props.updatedMenuItems}
                                    setUpdatedMenuItems={props.setUpdatedMenuItems}
                                    dirty={props.dirty}
                                    isValid={props.isValid}
                                    isLoading={props.isLoading}
                                />
                            }
                        </Panel>
                    </CustomScrollbars>
                    <BorderLeftCustomScrollbars autoHide autoHeight autoHeightMin={windowSize.windowSize.innerHeight - 210} autoHeightMax={windowSize.windowSize.innerHeight - 210}>
                        <Panel>
                            <MenuItemEditorHeader>Edit Menu Item</MenuItemEditorHeader>
                            <HorizontalSeparator />
                            <MenuItemEditorWrapper>
                                {props.updatedMenuItems && (props.selectedMenuItem) ?
                                    <VoicifyMenuEditor
                                        menuItem={props.selectedMenuItem?.menuItem}
                                        handleChange={handleMenuItemChange}
                                    />
                                    :
                                    <EmptyViewText>
                                        Select a menu item to see more details and manage values
                                    </EmptyViewText>
                                }
                            </MenuItemEditorWrapper>
                        </Panel>
                    </BorderLeftCustomScrollbars>
                </MenuWrapper>
            }
        </>
    );
};

export default VoicifyMenuItemsEditor;

const MenuWrapper = styled.div`
    height: 100%;
    display: flex;
`;

const Panel = styled.div`
    padding: 16px 32px 16px 32px;
`;

const BorderLeftCustomScrollbars = styled(CustomScrollbars)`
    border-left: 1px solid ${color_shades_dark};
`;

const MenuItemEditorWrapper = styled.div`
    margin-top: 16px;
`;

const MenuItemEditorHeader = styled.div`
    display: flex;
    align-items: center;
    height: 64px;
`;

const EmptyViewText = styled.div`
    background: white;
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    color: ${color_shades_dark};
    padding: 24px;
    margin-top: 24px;
`;

const EmptyViewPadding = styled.div`
    margin-top: 24px;
    width: 100%;
`;

const CustomLoader = styled(Loader)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
