import React, { useEffect, useState } from "react";
import ToggleIndicator, { Direction } from "../../../components/general/ToggleIndicator";
import { css } from '@emotion/css';
import EditableIntent from "../../../models/interactionModel/EditableIntent";
import Button from "../../../components/general/Button";
import { color_shades_dark, color_shades_lighter, color_text_default, color_colors_gold, color_variants_gold_light, color_shades_darkest_transparent } from "../../../constants/colors";
import LanguageModel from "../../../models/features/api/LanguageModel";
import LanguageCollectionAvatar from "../../../components/general/LanguageCollectionAvatar";
import GroupedUtterance from "../../../models/interactionModel/GroupedUtterance";
import Switch from "../../../components/forms/Switch";
import _ from "lodash";
import EditableNlpEntity from "../../../models/nlpEntity/EditableNlpEntity";
import ModalLayout from "../../../components/structure/ModalLayout";
import PageContainer from "../../../components/structure/PageContainer";
import HorizontalContainer from "../../../components/structure/HorizontalContainer";
import ModalTitle from "../../../components/general/ModalTitle";
import HorizontalSeparator from "../../../components/structure/HorizontalSeparator";
import CustomScrollbars from "../../../components/structure/CustomScrollbars";
import FooterBar from "../../../components/structure/FooterBar";
import styled from "@emotion/styled";
import EditableSlot from "../../../models/interactionModel/EditableSlot";
import Utterance from "../../../models/interactionModel/Utterance";
import PageError from "../../../components/general/PageError";
import useWindowSize from "../../../hooks/UseWindowSize";
import { FormikErrors, getIn } from "formik";
import ErrorLabel from "../../../components/general/ErrorLabel";
import FieldError from "../../../components/forms/FieldError";
import { extractSlots, InteractionModelFormData } from "./InteractionModelForm";
import NlpContainer from '../../../hooks/NlpContainer';
import NlpModelTrainingContainer from "../../../hooks/NlpModelTrainingContainer";
import AssistantApplicationModel from "../../../models/applications/api/AssistantApplicationModel";
import ExtractedEntity from "../../../models/nlp/ExtractedEntity";
import InteractionModelInput from "./interactionModelInput";
import TooltipWrapper from "../../../components/general/TooltipWrapper";
import { CountButton } from "../../contentPage/components/CountButton";
import DisableForFeatureFlagOptions from "../../contentPage/components/DisableForFeatureFlagOptions";
import ApplicationContainer from "../../../state/containers/ApplicationContainer";

const deleteIcon = require('../../../content/images/trash.svg');
const pencilGrey = require('../../../content/images/pencil-dark.svg');
const quoteIcon = require('../../../content/images/quote-gray.svg');
const addIcon = require('../../../content/images/add-blue.svg');
const greenCircleCheck = require('../../../content/images/green-check-circle-2.svg');
const redNoCircle = require('../../../content/images/red-no-circle.svg');
const yellowInfoCircle = require('../../../content/images/info-circle-yellow.svg');
const yellowUpdate = require('../../../content/images/yellow-update.svg');
const closeIcon = require('../../../content/images/close_icon.svg');
const alertIcon = require('../../../content/images/alert-circle.svg');
const enhancedIcon = require('../../../content/images/wand.svg');

interface IntentItemProps {
    displayType: "List" | "Modal"
    // list view props
    toggleActivationByKey?: (intentKey: string) => void;
    isActive?: boolean;
    idx?: number;

    // modal view props
    handleClose?: () => any
    handleSubmit?: () => any
    updateIntent: (initialName: string, intent: EditableIntent) => string;
    applicationId: string;
    languages?: LanguageModel[];
    intent: EditableIntent;
    isLoading: boolean;
    nlpEntities: EditableNlpEntity[];
    dirty?: boolean;
    isValid?: boolean;
    errors?: FormikErrors<InteractionModelFormData>;
    validateForm?;
    displayLanguages?: boolean;
    enhancedIntentMatchingEnabled: boolean;
    appContainer: ApplicationContainer
}

const IntentItem: React.FC<IntentItemProps> = (props) => {

    const [editingPhrase, setEditingPhrase] = useState("" as string);
    const [editingIntentName, setEditingIntentName] = useState("" as string);
    const [editingIntentDescription, setEditingIntentDescription] = useState("" as string);
    const [utterancesError, setUtterancesError] = useState([] as string[]);
    const [intentError, setIntentError] = useState([] as string[]);
    const [validationError, setValidationError] = useState([] as string[]);
    const [editingFeatureFlags, setEditingFeatureFlags] = useState(false);
    const [assistantApplication, setAssistantApplication] = useState(null as AssistantApplicationModel);
    const windowSize = useWindowSize();
    const nlpContainer = NlpContainer.useContainer();
    const modelTrainingContainer = NlpModelTrainingContainer.useContainer();

    const utteranceTextInput = React.createRef<HTMLInputElement>();
    const intentNameTextInput = React.createRef<HTMLInputElement>();
    const intentDescriptionTextInput = React.createRef<HTMLInputElement>();
    const hasUnsavedChanges: boolean = props.intent.isDeleted || props.intent.isAdded || props.intent.isModified || props.intent.updatedDisabledState;

    const isActive: boolean = props.isActive;
    const isCustom: boolean = props.intent.isCustom;
    const isDeleted: boolean = props.intent.isDeleted;
    const intentName: string = props.intent.displayName;
    const intentKey: string = props.intent.key;

    const intentField = `interactionModel.${intentKey}`;
    const displayNameField = `interactionModel.${intentKey}.displayName`;
    const slotsField = `interactionModel.${intentKey}.slots`;
    const utterancesField = `interactionModel.${intentKey}.utterances`;

    const hasErrors = getIn(props.errors, intentField);
    const displayNameFieldError = getIn(props.errors, displayNameField);
    const hasSlotsErrors = getIn(props.errors, slotsField);
    const intentLanguages = props.languages?.filter(l => props.intent.locales?.some(locale => locale.shortCode == l.shortCode));

    useEffect(function refreshDisplayIntentErrors() {
        setValidationError([]);
        setUtterancesError([]);

        if (hasErrors) {
            const intentFormErrors: string[] = displayNameFieldError ? [displayNameFieldError] : [];
            const slotsFormErrors: string[] = hasSlotsErrors?.map(error => {
                let rtn: string[] = [];
                if (error?.name) rtn.push(error?.name);
                if (error?.entityName) rtn.push(error?.entityName);
                return rtn;
            })

            if (intentFormErrors) setIntentError(intentFormErrors);
            if (slotsFormErrors) setValidationError(slotsFormErrors);
        }

    }, [hasErrors, isActive]);

    useEffect(() => {
        intentNameTextInput.current?.focus();
    }, [editingIntentName]);

    useEffect(() => {
        intentDescriptionTextInput.current?.focus();
    }, [editingIntentDescription]);

    const detectSlots = async (input: string): Promise<string> => {
        if (!props.intent?.slots?.length)
            return input;
        let assistantApp = modelTrainingContainer.currentApp;
        if (assistantApp?.id != props.applicationId) {
            var appResult = await modelTrainingContainer.loadAssistantApplication(props.applicationId);
            assistantApp = appResult.data;
        }
        const appId = assistantApp?.id;
        const appSecret = assistantApp?.secret;
        const env = assistantApp?.environments?.find(e => e.name == "Production");
        const modelId = env?.languages?.[0].nlpModelId;

        if (!appId?.length || !appSecret?.length || !env?.id?.length || !modelId?.length) {
            return input;
        }

        const nlpResult = await nlpContainer.runNlp(appId, appSecret, env.id, input, modelId);
        if (nlpResult.resultType !== "Ok")
            return input;
        const entities = nlpResult.data.extractedEntities;
        interface EntityWithSlot extends ExtractedEntity {
            slotName: string;
        }
        const entitiesUsed: ExtractedEntity[] = [];
        const entitiesToUse: EntityWithSlot[] = [];
        (props.intent?.slots ?? []).forEach(slot => {
            const toAdd = entities?.filter(e => e.name === slot.entityName || e.entityId === slot.entityId) ?? [];
            toAdd.forEach(ta => {
                if (!entitiesUsed?.includes(ta)) {
                    entitiesUsed.push(ta);
                    entitiesToUse.push({
                        ...ta,
                        slotName: slot.name
                    })

                }
            })
        });

        // sort entitiesTouse by start property ascending
        entitiesToUse.sort((a, b) => a.start - b.start);
        const nonNested = [];
        for (let i = 0; i < entitiesToUse.length; i += 1) {
            if (entitiesToUse.some((ie, idx) =>
                idx !== i &&
                (
                    ie.start <= entitiesToUse[i].start && ie.end > entitiesToUse[i].end ||
                    ie.start < entitiesToUse[i].start && ie.end >= entitiesToUse[i].end
                )
            )) {
                // this entity is nested and we shouldn't use it  
            } else {
                nonNested.push(entitiesToUse[i]);
            }
        }
        let index = 0;
        let result = '';
        for (let i = 0; i < nonNested.length; i += 1) {
            const entity = nonNested[i];
            const left = input.slice(index, entity.start);
            index = entity.end + 1;
            result += left;
            result += `{${entity.slotName}}`;
        }
        const right = input.slice(nonNested[nonNested.length - 1]?.end + 1);
        result += right;
        return result;
    }

    const handleIntentItemToggleActivation = () => {
        props.toggleActivationByKey(intentKey)
    };

    const toggleIsDeleted = () => {
        let editable = { ...props.intent }
        editable.isDeleted = !editable.isDeleted;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
        else {
            props.toggleActivationByKey(intentKey);
        }
    };

    const toggleRequiresExactUtteranceMatch = () => {
        let editable = { ...props.intent }
        editable.didUpdateRequiresExactMatch = true
        editable.requiresExactUtteranceMatch = !editable.requiresExactUtteranceMatch;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
    };
    const toggleAllowEnhanced = () => {
        let editable = { ...props.intent }
        editable.allowEnhancedIntentMatching = !editable.allowEnhancedIntentMatching;
        editable.isModified = true;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
    };
    const toggleAlwaysInclude = () => {
        let editable = { ...props.intent }
        editable.alwaysIncludeInPrompt = !editable.alwaysIncludeInPrompt;
        editable.isModified = true;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
    };

    const toggleIsDisabled = () => {
        let editable = { ...props.intent }
        editable.isDisabled = !editable.isDisabled;
        editable.updatedDisabledState = true;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
        else {
            props.toggleActivationByKey(intentKey);
        }
    };

    const changeName = (newName: string) => {
        let editable = { ...props.intent }
        editable.displayName = newName;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
    };

    const changeDescription = (newDescription: string) => {
        let editable = { ...props.intent }
        editable.enhancedMatchingDescription = newDescription;
        const error = props.updateIntent(intentName, editable);
        if (error) {
            setIntentError([error]);
        }
    };

    const setEditingIntentDescriptionState = (editing: boolean) => {
        if (!editing) {
            const updatedDescription = intentDescriptionTextInput.current.value;
            if (props.intent.enhancedMatchingDescription != updatedDescription) {
                changeDescription(updatedDescription);
            }
            setEditingIntentDescription(null);
        } else {
            setEditingIntentDescription(intentName);
        }
    };

    const handleEditingIntentDescriptionKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        e.stopPropagation();
        if (e.key === "Enter") {
            setEditingIntentDescriptionState(false);
        }
        else if (e.key === "Escape") {
            setEditingIntentDescription(null);
        }
    };

    const setEditingIntentNameState = (editing: boolean) => {
        if (!editing) {
            const editedName = intentNameTextInput.current.value;
            if (intentName != editedName) {
                changeName(editedName);
            }
            setEditingIntentName(null);
        } else {
            setEditingIntentName(intentName);
        }
    };

    const handleEditingIntentNameKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        e.stopPropagation();
        if (e.key === "Enter") {
            setEditingIntentNameState(false);
        }
        else if (e.key === "Escape") {
            setEditingIntentName(null);
        }
    };

    const handleEditingUtteranceKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, utterance: string): void => {
        e.stopPropagation();
        if (e.key === "Enter") {
            setEditingUtteranceState(false, utterance);
        }
        else if (e.key === "Escape") {
            setEditingPhrase(null);
        }
    };

    const utteranceExists = (phrase: string, lang: LanguageModel): boolean => {
        const utteranceMatch = props.intent.utterances
            .filter(u => u.changeType != 'Removed' && u.phrase.toLowerCase().trim() === phrase.toLowerCase().trim() && u.language.id == lang.id);
        return (utteranceMatch?.length > 0)
    }

    const addUtterance = (phrase: string, languages: LanguageModel[]) => {
        const intent = { ...props.intent }
        languages.forEach(lang => {
            // If utterance already exists - do nothing, if it does not exist - add it
            if (!utteranceExists(phrase, lang)) {
                intent.utterances.unshift({ phrase: phrase, changeType: "Added", language: lang });
            }
        });

        const error = props.updateIntent(intentName, intent);
        if (error) {
            setIntentError([error]);
        }
    }

    const handleAddUtteranceToAllLanguages = (utterance: GroupedUtterance) => {
        const languagesToAdd = intentLanguages.filter(l => !utterance.languages.some(ul => ul.id == l.id));
        addUtterance(utterance.phrase, languagesToAdd);
    };

    const removeUtterance = (intent: EditableIntent, phrase: string, language: LanguageModel) => {
        if (utteranceExists(phrase, language)) {
            const utterance = intent.utterances.filter(u => u.phrase === phrase && u.language.id == language.id)[0];
            if (utterance.changeType == "Added") {
                var idx = intent.utterances.indexOf(utterance);
                intent.utterances.splice(idx, 1);
            } else {
                utterance.changeType = "Removed";
            }
        }
        return;
    };

    const removeUtteranceFromAllLanguages = (utterance: string) => {
        const intent = { ...props.intent };
        intentLanguages.forEach(lang => {
            removeUtterance(intent, utterance, lang);
        })

        const error = props.updateIntent(intentName, intent);
        if (error) {
            setIntentError([error]);
        }
    };

    const updateSlots = (updatedSlots: EditableSlot[]) => {
        // Interaction Model Update is going to remove old slots and add new slots
        const intentToUpdate = { ...props.intent };
        let intentUtterances = [...intentToUpdate.utterances];

        intentToUpdate.slots = updatedSlots;

        // If existing slot name was updated then we need to replace old slot with changed slot in utterances that use it
        updatedSlots.filter(slot => !slot.isDeleted && slot.name != slot.originalName)
            ?.forEach((modifiedSlot) => {
                const slotOriginalName: string = modifiedSlot.originalName;
                const slotNewName: string = modifiedSlot.name;

                intentUtterances?.forEach((utterance) => {
                    const utteranceSlots = extractSlots(utterance.phrase);
                    const extractedPhraseSlots = utteranceSlots?.map(s => s.getSlotName());

                    extractedPhraseSlots?.forEach(slotName => {

                        if (slotName == slotOriginalName) {
                            // Slot was used in utterance
                            const oldSlot: string = `{${slotOriginalName}}`;
                            const newSlot: string = `{${slotNewName}}`;
                            const newPhrase = utterance.phrase.replace(oldSlot, newSlot);

                            intentLanguages.forEach(lang => {
                                updateUtterance(intentToUpdate, utterance.phrase, newPhrase, lang);
                            });
                        }
                    })

                });
            })

        // Remove utterances that use deleted slots
        if (updatedSlots?.some(s => s.isDeleted)) {
            intentUtterances = [...intentToUpdate.utterances];
            const utterancesToRemove: Utterance[] = [];

            updatedSlots.filter(slot => slot.isDeleted)
                ?.forEach((deletedSlot) => {
                    const deletedSlotName = deletedSlot.originalName ? deletedSlot.originalName : deletedSlot.name;

                    intentUtterances?.forEach((utterance) => {
                        const utteranceSlots = extractSlots(utterance.phrase);
                        const extractedPhraseSlots = utteranceSlots?.map(s => s.getSlotName());

                        extractedPhraseSlots?.forEach(slotName => {
                            if (slotName == deletedSlotName) {
                                utterancesToRemove.push(utterance);
                            }
                        })

                    });
                })

            utterancesToRemove?.forEach(utterance => {
                intentLanguages.forEach(lang => {
                    removeUtterance(intentToUpdate, utterance.phrase, lang);
                })
            })
        }

        // Filter out newly added and then deleted slots
        const modifiedSlots = updatedSlots.filter(slot => !(slot.isAdded && slot.isDeleted));
        intentToUpdate.slots = modifiedSlots;

        const error = props.updateIntent(intentName, intentToUpdate);
        if (error) {
            setIntentError([error]);
        }
    };

    const validateUtterance = (phrase: string): string[] => {
        const intent = props.intent;
        const errors = [] as string[];

        const nonDeletedSlots = intent.slots ? intent.slots.filter(s => !s.isDeleted) : [];

        const requiredSlots = nonDeletedSlots ? nonDeletedSlots.filter(s => s.required).map(s => s.name) : [];
        const availableSlots = nonDeletedSlots ? nonDeletedSlots.map(s => s.name) : []
        const extractedPhraseSlots = extractSlots(phrase).map(s => s.getSlotName());

        const existingUtterance = intent.utterances?.find(u => u.phrase === phrase);
        if (existingUtterance) {
            errors.push(`That utterance already exists for this intent.`);
            return errors;
        }

        if (!phrase || phrase.length < 1)
            errors.push(`Phrase cannot be empty`);

        const missingSlots = _.difference(requiredSlots, extractedPhraseSlots);
        if (missingSlots && missingSlots.length > 0) {
            missingSlots.forEach(s => { errors.push(`Slot {${s}} is required`) });
        }

        const nonExistentSlots = _.difference(extractedPhraseSlots, availableSlots)
        if (nonExistentSlots && nonExistentSlots.length > 0) {
            nonExistentSlots.forEach(s => { errors.push(`{${s}} is not a valid slot for "${intent.displayName}" intent `) })
        }
        return errors;
    };

    const updateUtterance = (intent: EditableIntent, oldPhrase: string, newPhrase: string, language: LanguageModel) => {
        const idx = intent.utterances.map(u => u.phrase).indexOf(oldPhrase);
        const utterance = intent.utterances[idx];

        if (utterance.changeType == "Original") {
            intent.utterances[idx].changeType = "Removed";
            intent.utterances.splice(idx, 0, { phrase: newPhrase, changeType: "Added", language });
        }
        else if (utterance.changeType == "Added") {
            intent.utterances[idx].phrase = newPhrase;
        }
        //else:  Do not care about Removed
    };

    const editUtterance = (intent: EditableIntent, oldPhrase: string, newPhrase: string, language: LanguageModel) => {
        if (utteranceExists(oldPhrase, language) && !utteranceExists(newPhrase, language)) {
            const errors = validateUtterance(newPhrase);
            if (errors.length > 0) {
                setUtterancesError([
                    ...utterancesError ?? [],
                    ...errors ?? []
                ])
                utteranceTextInput.current?.focus();
                return;
            }
            updateUtterance(intent, oldPhrase, newPhrase, language);

            return;
        } else {
            setUtterancesError([...utterancesError ?? [], "Validation error: Phrase already exists"]);
            return;
        }
    };

    const setEditingUtteranceState = (editing: boolean, utterance: string) => {
        if (!editing) {
            if (utterance != utteranceTextInput.current.value) {

                const intent = { ...props.intent };
                intentLanguages.forEach(lang => {
                    editUtterance(intent, utterance, utteranceTextInput.current.value, lang);

                    setEditingPhrase(null);
                    setUtterancesError([]);
                });

                const error = props.updateIntent(intentName, intent);
                if (error) {
                    setIntentError([error]);
                }
            } else {
                setUtterancesError([]);
                setEditingPhrase(null);
            }
        } else {
            setUtterancesError([]);
            setEditingPhrase(utterance);
        }
    };

    const renderItemHeaderToggle = (direction: Direction) => {
        const nonRemovedUtterances = props.intent.utterances.filter(u => u.changeType != "Removed");
        const uniqueUtterances = [...new Set(nonRemovedUtterances.map(u => u.phrase))];


        return (
            <div className={itemBody}
                key={`menuItem-${props.idx}`}
                onClick={(e) => handleIntentItemToggleActivation()}>

                <ToggleIndicator className={"toggle-indicator"} direction={direction} />

                {renderListIntentDisplayName()}

                <div className="counter-container">

                    {hasErrors &&
                        <ErrorLabel image={alertIcon} text={"Errors Present"} />
                    }
                    {props.displayLanguages &&
                        <div className="enabled-container">
                            <LanguageCollectionAvatar languages={intentLanguages} limit={3} />
                        </div>
                    }
                    {hasUnsavedChanges &&
                        <div className="enabled-container">
                            <p>{isDeleted ? "Deleted" : "Modified"}</p>
                            <img src={isDeleted ? redNoCircle : yellowUpdate} />
                        </div>
                    }
                    <p>Phrases:</p>
                    <div className={"count" + (nonRemovedUtterances.length == 0 ? " empty" : "")}>
                        <span>{uniqueUtterances.length}</span>
                    </div>
                </div>

                <div className="enabled-container">
                    <p>{props.intent.isDisabled ? "Disabled" : "Enabled"}</p>
                    <img src={props.intent.isDisabled ? redNoCircle : greenCircleCheck} />
                </div>

            </div>
        )
    }

    const renderActiveIntentEditingContent = () => {

        return (
            <>
                <div className={editingContainer}>
                    <div className="enabled-switch-container">
                        <EnhancedIntentSettingsRow>
                            <MatchingEnabledSwitch
                                className="enabled-switch"
                                onChange={() => toggleAllowEnhanced()}
                                disabled={!props.enhancedIntentMatchingEnabled}
                                checked={props.intent.allowEnhancedIntentMatching}
                                leftOption={!props.enhancedIntentMatchingEnabled ? "LLM intent matching disabled for model" : "LLM Matching"} />
                            {(props.intent.allowEnhancedIntentMatching && props.enhancedIntentMatchingEnabled) && (
                                <EnhancedIntentSettingsRow>
                                    <AlwaysIncludeSwitch
                                        className="enabled-switch"
                                        onChange={() => toggleAlwaysInclude()}
                                        checked={props.intent.alwaysIncludeInPrompt}
                                        leftOption={"Always include in prompt"} />
                                    {
                                        editingIntentDescription === intentName ?
                                            <DescriptionInput type="text"
                                                defaultValue={props.intent.enhancedMatchingDescription}
                                                placeholder={"When the user ..."}
                                                ref={intentDescriptionTextInput}
                                                onClick={(e) => e.stopPropagation()}
                                                onBlur={() => setEditingIntentDescriptionState(false)}
                                                onKeyUp={(e) => handleEditingIntentDescriptionKeyUp(e)}
                                            />
                                            :
                                            <DescriptionPreviewRow>
                                                <EditDescriptionButton themes={['white-small', 'end-tight']}
                                                    text="Edit Description"
                                                    icon={pencilGrey}
                                                    type="button"
                                                    onClick={() => setEditingIntentDescriptionState(true)} />
                                                <DescriptionPreview>
                                                    {props.intent.enhancedMatchingDescription?.length ? props.intent.enhancedMatchingDescription : "No description set"}
                                                </DescriptionPreview>
                                            </DescriptionPreviewRow>
                                    }
                                </EnhancedIntentSettingsRow>
                            )}
                        </EnhancedIntentSettingsRow>
                        <DisabledForFeaturesButton themes={['white-small', 'end-tight']}
                            onClick={() => { setEditingFeatureFlags(!editingFeatureFlags); }}
                            text={`Disabled for ${props.intent.disabledForFeatureFlagIds?.length ?? 0} Features`}
                            icon={pencilGrey}
                        />
                        {isCustom &&
                            <>
                                <Button themes={['white-small', 'end-tight']}
                                    text="Edit Name"
                                    icon={pencilGrey}
                                    type="button"
                                    onClick={() => setEditingIntentNameState(true)} />

                                {!isDeleted && props.displayType === "List" &&
                                    <Button themes={['white-small']}
                                        text="Remove Intent"
                                        type="button"
                                        icon={deleteIcon}
                                        onClick={() => toggleIsDeleted()} />
                                }
                            </>
                        }
                        {props.displayType === "List" ?
                            <>
                                <RequiresExactMatchSwitch
                                    className="enabled-switch"
                                    onChange={() => toggleRequiresExactUtteranceMatch()}
                                    checked={props.intent.requiresExactUtteranceMatch}
                                    leftOption={"Requires Exact Match"} />
                                {props.intent.disabledForFeatureFlagIds?.length ? <></> : <Switch
                                    className="enabled-switch"
                                    key={intentKey}
                                    onChange={() => toggleIsDisabled()}
                                    checked={!props.intent.isDisabled}
                                    leftOption={props.intent.isDisabled ? "Disabled" : "Enabled"} />}

                            </>
                            :
                            <Filler />
                        }
                    </div>
                    {props.intent.isDisabled &&
                        <div className={disabledWarningStyle}>
                            <img src={yellowInfoCircle} />
                            <p>This intent is currently disabled. Any changes you make to it will not be reflected in your app’s interaction model</p>
                        </div>
                    }
                    {editingFeatureFlags && <DisableForFlagsContainer>
                        <DisableForFeatureFlagOptions
                            titleOverride={"Disable this intent if feature flags are present"}
                            descriptionOverride={"If a checked feature flag is present in this application, this intent will be disabled."}
                            appContainer={props.appContainer}
                            onChange={(value) => {
                                const intent = { ...props.intent };
                                intent.disabledForFeatureFlagIds = value;
                                const error = props.updateIntent(intentName, intent);
                                if (error) {
                                    setIntentError([error]);
                                }
                            }}
                            selectedFeatureFlags={props.intent.disabledForFeatureFlagIds}
                        />

                    </DisableForFlagsContainer>}


                    <InteractionModelInput
                        nlpEntities={props.nlpEntities}
                        validateUtterance={validateUtterance}
                        extractSlots={extractSlots}
                        addUtterance={addUtterance}
                        updateSlots={updateSlots}
                        setError={setUtterancesError}
                        isLoading={props.isLoading || nlpContainer.loading}
                        intent={props.intent}
                        detectSlots={(text) => detectSlots(text)}
                        intentName={intentName}
                        applicationId={props.applicationId}
                        languages={intentLanguages}
                    />

                    {renderUtterancesContainer()}

                    <PageError className={pageErrorStyle}
                        errors={[
                            ...intentError ?? [],
                            ...utterancesError ?? [],
                            ...validationError ?? []
                        ]} onClear={() => {
                            setIntentError([]);
                            setUtterancesError([]);
                            setValidationError([]);
                        }} />
                </div>
            </>
        )
    }

    const renderIntentName = () => {
        return (
            <IntentNameRow>
                <div>{intentName}</div>
                {props.intent.allowEnhancedIntentMatching ? <TooltipWrapper place="right" text="LLM Intent Matching Enabled"><img src={enhancedIcon} /></TooltipWrapper> : null}
            </IntentNameRow>
        )
    }

    const renderListIntentDisplayName = () => {
        if (isActive) {
            return (
                <>
                    {editingIntentName != intentName ?
                        renderIntentName()
                        :

                        <NameInput type="text"
                            defaultValue={intentName}
                            ref={intentNameTextInput}
                            onClick={(e) => e.stopPropagation()}
                            onBlur={() => setEditingIntentNameState(false)}
                            onKeyUp={(e) => handleEditingIntentNameKeyUp(e)}
                        />
                    }
                </>
            )
        }
        else {
            return (
                renderIntentName()
            )
        }
    }

    const renderModalIntentDisplayName = () => {
        return editingIntentName != intentName ?
            <ModalTitle padded>{intentName}</ModalTitle>
            :
            <ModalNameInput type="text"
                defaultValue={intentName}
                ref={intentNameTextInput}
                onClick={(e) => e.stopPropagation()}
                onBlur={() => setEditingIntentNameState(false)}
                onKeyUp={(e) => handleEditingIntentNameKeyUp(e)}
            />
    }

    const renderUtteranceItem = (utterance: GroupedUtterance, utteranceIdx: number) => {
        if (utterance.changeType != "Removed") {
            const phrase = utterance?.phrase;

            return (
                <div key={utteranceIdx} className={utteranceItem} >
                    {editingPhrase != phrase ?
                        <div>
                            <img className="quote-icon" src={quoteIcon} />
                            {phrase}

                            <img className={"edit-icons edit"}
                                src={pencilGrey}
                                onClick={(e) => { e.stopPropagation(); setEditingUtteranceState(true, phrase); }}></img>

                        </div>
                        :
                        <NameInput type="text"
                            defaultValue={phrase}
                            ref={utteranceTextInput}
                            autoFocus={true}
                            onBlur={() => setEditingUtteranceState(false, phrase)}
                            onKeyUp={(e) => handleEditingUtteranceKeyUp(e, phrase)}
                        />
                    }
                    <div className="end-container">
                        <div className="utterance-field-error">
                            <FieldError name={`${utterancesField}.${utteranceIdx}.phrase`} ignoreTouched={true} />
                        </div>
                        {utterance.languages.length < intentLanguages.length ?
                            <>
                                <Button
                                    themes={['hollow', 'flat']}
                                    icon={addIcon}
                                    onClick={() => handleAddUtteranceToAllLanguages(utterance)}
                                    text="Apply to all" />
                                <div className="unselected-flags">
                                    <LanguageCollectionAvatar languages={intentLanguages.filter(l => !utterance.languages.some(ul => ul.id == l.id))} limit={3} />
                                </div>
                            </>
                            : null}
                        <LanguageCollectionAvatar languages={utterance.languages} limit={3} />
                        <img src={deleteIcon}
                            className={"edit-icons delete"}
                            onClick={() => removeUtteranceFromAllLanguages(phrase)}>
                        </img>
                    </div>
                </div>
            )
        }
    };

    const renderUtterancesContainer = () => {
        const utteranceGroups: GroupedUtterance[] = [];
        props.intent.utterances.forEach((utterance) => {
            const phrase = utterance.phrase;
            const existingGroup = utteranceGroups.find(u => u.phrase == phrase);
            if (existingGroup) {
                existingGroup.languages.push(utterance.language);
            }
            else {
                utteranceGroups.push({ ...utterance, languages: [utterance.language] });
            }
        });

        return (
            <div className={utteranceContainer}>
                {
                    utteranceGroups.map((u, idx) => {
                        return renderUtteranceItem(u, idx);
                    })
                }
            </div>
        )
    }

    const renderIntentListItem = () => {
        if (isActive) {
            return (
                <div className={activeItemContainer} key={`active-${props.idx}`}>
                    {renderItemHeaderToggle("down")}
                    {renderActiveIntentEditingContent()}
                </div>
            )
        }
        else {
            return renderItemHeaderToggle("right");
        }
    }

    const renderIntentModalItem = () => {
        return (
            <ModalLayout
                allowOverflow
                isVisible={true}
                onClose={() => props.handleClose()} >
                <PageContainer fill withFooter>
                    <HorizontalContainer>
                        {renderModalIntentDisplayName()}
                        <Button themes={['end-tight', 'icon']}
                            icon={closeIcon}
                            onClick={() => props.handleClose()} />
                    </HorizontalContainer>
                    <HorizontalSeparator />
                    <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - 188}>
                        {renderActiveIntentEditingContent()}
                    </CustomScrollbars>
                    <CustomFooter usedInModal>
                        <SubmitButton
                            type="button"
                            themes={['primary']}
                            text="Save"
                            onClick={() => props.handleSubmit()}
                            disabled={props.isLoading}
                            loading={props.isLoading} />
                        <Button
                            type="button"
                            themes={['secondary']}
                            text="Cancel"
                            onClick={() => props.handleClose()}
                            disabled={props.isLoading}
                            loading={props.isLoading} />
                    </CustomFooter>
                </PageContainer>
            </ModalLayout>
        )
    }

    return (
        props.displayType === "List" ?
            renderIntentListItem()
            :
            renderIntentModalItem()
    );

};

export default IntentItem;

const DisabledForFeaturesButton = styled(Button)`
    margin-right: 16px;
`
const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;

const activeItemContainer = css`
    position:relative;
`;

const DisableForFlagsContainer = styled.div`
    margin: 24px;
`;
const itemBody = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    border: solid 1px ${color_shades_dark};
    font-size: 18px;
    color: ${color_text_default};
    position:relative;
    cursor: pointer;

    /* Edit Custom Intent Display Name Input */
    input {
        background-color: white;
        width: 97%;
        border-radius: 5px;
        border: solid 1px #cccccc;
        padding: 12px;
        height: 40px;
        margin-top: 8px;
        margin-right: 36px;

        font-size: 18px;
        color: ${color_text_default};
    }
    .toggle-indicator {
        margin: 16px;
    }

    .enabled-container {
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-left: 16px;
        p {
            font-size: 14px;
            color: ${color_text_default};
            margin-right: 12px;
        }
        img {
            height: 24px;
            width: 24px;
        }
    }

    .counter-container {
        display: flex;
        align-items: center;
        margin-right: 16px;
        margin-left: auto;

        p {
            font-size: 14px;
            color: ${color_text_default};
            margin-right: 12px;
        }

        .count {
            width: 30px;
            max-height: 22px;
            border-radius: 11px;
            border: solid 1px #8e97a1;
            background: #8e97a1;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center !important;
            span {
                font-size: 14px;
                text-align: center;
            }
            &.empty {
                background: white;
                color: ${color_text_default};
            }
        }
    }
`;

const pageErrorStyle = css`
    top: 92;    
    bottom: inherit;
`;

const editingContainer = css`
    background-color: ${color_shades_lighter};
    border: solid 1px ${color_shades_dark};
    width: 100%;
    position: relative;
    .enabled-switch-container {
        display: flex;
        justify-content: flex-end;
    }
    .enabled-switch {
        margin-right: 24px;
        margin-top: 16px;
        margin-bottom: 16px;
        margin-left: 0px;
        .primary-label {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: ${color_text_default};
        }
    }
`;

const disabledWarningStyle = css`
    border-radius: 5px;
    margin: 30px;
    padding: 24px;
    background: ${color_variants_gold_light};
    border: 1px solid ${color_colors_gold};
    display: flex;
    flex-direction: row;
    img {
        margin-right: 16px;
    }
`;

const utteranceContainer = css`
    border: solid 1px ${color_shades_dark};
    margin: 30px;
    margin-top: 0px;
    border-top: 0px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
`;

const Filler = styled.div`
    width: 32px;
    height: 32px;
`;

const MatchingEnabledSwitch = styled(Switch)`
    margin-right: 0px !important;
    min-width: 160px;
`;
const AlwaysIncludeSwitch = styled(Switch)`
    min-width: 224px;
`;

const DescriptionInput = styled.input`
    height: 42px;
    border-radius: 8px;
    border: solid 1px #cccccc;
    background: white;
    padding: 0 16px;
    flex: 1;
    margin-right: 16px;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color_text_default};
    margin-top: 10px;
`;

const NameInput = styled.input`
    height: 42px;
    border-radius: 5px;
    border: solid 1px #cccccc;
    background: white;
    padding: 0 16px;
    flex: 1;
    margin-right: 48px;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color_text_default};    
`;

const ModalNameInput = styled(NameInput)`
    margin: 16px 32px 16px 32px;
`;

const EnhancedIntentSettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 32px;
    width: 100%;
    overflow: hidden;
`;

const EditDescriptionButton = styled(Button)`
    margin-left: 8px;
`;

const DescriptionPreview = styled.div`
    max-width: 30%;
    margin-left: 16px;
    margin-bottom: 4px;
    color: ${color_shades_darkest_transparent};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-style: italic;    
    display: inline-block;
`;

const DescriptionPreviewRow = styled.div`
    display: inline-block;
    flex-direction: row;
    width: 100%;
    margin-left: 8px;
`;

const utteranceItem = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
    border-top: solid 1px ${color_shades_dark};
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;

    .utterance-field-error {
        margin: 0 16px 0 0;
    }
    
    .edit-icons {
        margin-left: 12px;
        cursor:pointer;

        &.edit {
            height: 16px;
        }
    }
    .quote-icon {
        margin-right: 12px;
        height: 12px;
    }
    .end-container {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 0;

        button {
            .left-img {
                margin-right: 12px;
            }
        }

        .unselected-flags {
            padding: 0 24px;
            margin-right: 24px;
            border-right: 1px solid #cccccc;
            .inner-flag {
                img {
                    filter: grayscale(100%);
                }
            }
        }
    }
`;

const RequiresExactMatchSwitch = styled(Switch)`
    min-width: 210px;
`

const IntentNameRow = styled.div`
    display: flex;
    flex-direction: row;
    div {
        margin-right: 8px;
    }
`

