import { withFormik } from "formik";
import * as yup from 'yup';
import FallbackMessageForm, { EditFallbackMessageFormProps, EditFallbackMessageFormData } from '../../formScenes/FallbackMessageForm';

const EditFallbackMessageForm = withFormik<EditFallbackMessageFormProps, EditFallbackMessageFormData>({
    mapPropsToValues: props => ({
        title: props.fallbackMessage.title,
        categoryId: props.fallbackMessage.category ? props.fallbackMessage.category.id : '',
        responses: props.fallbackMessage.responses.length > 0 ? props.fallbackMessage.responses : [{ content: '' }],
        requiresParent: props.fallbackMessage.requiresParent,
        languages: props.fallbackMessage.languages,
        isLive: props.fallbackMessage.isLive,
        webhookChainingType: props.fallbackMessage.webhookChainingType,
        sensitiveConversationChange: props.fallbackMessage.sensitiveConversationChange ?? "None",
        disabledForFeatureFlagIds: props.fallbackMessage?.disabledForFeatureFlagIds,
    }),
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        categoryId: yup.string().required("You must select a category"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter an response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.fallbackMessage, values));
    }
})(FallbackMessageForm);

export default EditFallbackMessageForm;