import React from 'react';
import { FieldArray } from "formik";
import Button from '../../components/general/Button';
import ContentCard from '../../components/structure/ContentCard';
import PageContainer from '../../components/structure/PageContainer';
import FormFieldsContainer from '../../components/structure/FormFieldsContainer';
import TitleTextField from '../../components/forms/TitleTextField';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import ConversationItemFormProps from '../../models/features/ConversationItemFormProps';
import ConversationFlow from '../conversationFlow';
import Loader from '../../components/general/Loader';
import QuestionAnswerContainer from '../../state/containers/QuestionAnswerContainer';
import QuestionAnswerModel from '../../models/features/api/QuestionAnswers/QuestionAnswerModel';
import TextField from '../../components/forms/TextField';
import IResult from '../../models/result/IResult';
import { QuestionAnswerFeatureTypeId } from '../../constants/featureTypeIds';
import UpdateQuestionRequest from '../../models/features/api/QuestionAnswers/UpdateQuestionRequest';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import ContentPagePanel from '../contentPage';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import { getFeatureTypeIcon } from '../../models/extensions';
import RelatedContentMenu from '../relatedContentMenu';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import PanelTitle from '../contentPage/components/PanelTitle';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ResponsesManager from '../responsesManager';
import AnswerModel from '../../models/features/api/QuestionAnswers/AnswerModel';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import { ContentItemPageState, HighlightSection } from './BaseConversationItemForm';
import ResponseGroup from '../../models/features/ResponseGroup';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { color_colors_decline, color_colors_ocean, color_gradients_silver_shift, color_shades_darker, color_shades_darkest, color_text_default, color_variants_ocean_light_opaque, silver_four } from '../../constants/colors';
import HorizontalOptionsSelector from '../../components/forms/HorizontalOptionsSelector';
import { style_border_default } from '../../constants/stylesValues';
import HorizontalSeparator from '../../components/structure/HorizontalSeparator';
import { CountButton } from '../contentPage/components/CountButton';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';
import TextFieldSubtitle from '../../components/forms/TextFieldSubtitle';
import DisableForFeatureFlagOptions from '../contentPage/components/DisableForFeatureFlagOptions';

const userIcon = require('../../content/images/content-tree-icons/content-level/end-user-avatar.svg');
const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');
const inputGroupIcon = require('../../content/images/content-tree-icons/content-level/input-stack.svg');
const keywordsIcon = require('../../content/images/content-tree-icons/content-level/seo-zoom.svg');
const inputIcon = require('../../content/images/content-tree-icons/content-level/input-single.svg');
const requiredStarIcon = require('../../content/images/webhooks/parameter-required-star.svg');
const confidenceScoreIcon = require('../../content/images/content-tree-icons/content-level/check-shield.svg')
const validationIcon = require('../../content/images/content-item-icons/input-validate.svg');
const blueValidationIcon = require('../../content/images/content-item-icons/blue-input-validate.svg');

export interface QuestionAnswerContentFormData extends FollowUpWithRepromptFormData {
    title: string
    questions: UpdateQuestionRequest[]
    responses: AnswerModel[]
    webhookChainingType?: WebhookChainingType
    minimumConfidenceScore: number
    requiredPhrases: string[]
}

export interface QuestionAnswerContentFormProps extends FollowUpWithRepromptFormProps<QuestionAnswerModel>, ConversationItemFormProps<QuestionAnswerModel> {
    stateContainer: QuestionAnswerContainer
    questionAnswer?: QuestionAnswerModel
}

interface QuestionAnswerFormState extends ContentItemPageState {
    showValidation: boolean
}

class QuestionAnswerForm extends FollowUpWithRepromptForm<QuestionAnswerModel, QuestionAnswerContentFormProps, QuestionAnswerContentFormData, QuestionAnswerFormState> {

    constructor(props) {
        super(props);
        this.state = {
            showValidation: false
        }
    }
    toggleValidation() {
        this.setState({
            ...this.state,
            showValidation: !this.state.showValidation,
            showSensitiveConversation: false
        })
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.questionAnswer.id).then(result => {
                this.handleFinish();
            });
        }
    }
    handleCopy() {
        this.handleAsyncSubmit(() => {
            this.props.stateContainer.copy(this.props.questionAnswer.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/faqs/${r2.data.id}`);
                }
            });
        });
    }
    handleAsyncSubmit(onSuccess: (result: IResult<QuestionAnswerModel>) => void) {
        if (this.manualValidate())
            return this.props.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.questionAnswer, this.props.values), onSuccess);
    }
    getMenuTreeRoots() {
        var roots: TreeItem[] = [this.getContentInfoTreeItem(), {
            id: 'user-says',
            title: 'User-Says',
            isExpanded: this.isItemExpanded('user-says'),
            canExpand: true,
            icon: userIcon,
            linkTo: "#userSays",
            children:
                [
                    {
                        id: 'input-options',
                        title: 'Input Options',
                        canExpand: true,
                        isExpanded: this.isItemExpanded('input-options'),
                        icon: inputGroupIcon,
                        linkTo: "#inputOptions",
                        children: this.props.values.questions?.map((q, i) => ({
                            id: `q${i}`,
                            title: `Input Option #${i + 1}`,
                            canExpand: false,
                            icon: inputIcon,
                            linkTo: `#q${i}`
                        }))
                    }
                ]
        },
        ...this.getResponsesTreeItems(this.props.values.responses)
        ]

        return roots;
    }
    getContentItem() {
        return this.props.questionAnswer
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if (option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if (option == "Duplicate") {
            this.handleCopy();
        }
        else if (option == "Delete") {
            this.handleDelete();
        }
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    options = [
        { name: 'None', value: 0 },
        { name: 'Weak', value: .2 },
        { name: 'Fair', value: .4 },
        { name: 'Good', value: .6 },
        { name: 'Strong', value: .8 },
        { name: 'Exact', value: 1 },
    ]
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        }
        return (
            <>
                <form id='my-form' onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true} />
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.questionAnswer, featureTypeId: QuestionAnswerFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={QuestionAnswerFeatureTypeId}
                            followUp={responseGroups?.[0]?.firstResponse?.followUp}
                            contentId={this.props.questionAnswer?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={Object.keys(props.errors).length > 0}
                                    contentItem={props.questionAnswer}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.questionAnswer?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.questionAnswer.id, true)} />}
                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.questionAnswer?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection className="ac-content-info" title="Content Info" id="contentInfo" icon={contentInfoIcon} titleRightRowComponent={
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0}
                                                options={this.getOptions(this.props.questionAnswer != null)}
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)}
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation}
                                                toggleSensitiveConversation={() => { this.toggleSensitiveConversation(); }}
                                                showDisabledForFeatures={this.state.showDisabledForFeatureFlags}
                                                toggleDisabledForFeatures={() => { this.toggleDisableForFeatureFlags(); }}
                                                flagsSelected={props.values.disabledForFeatureFlagIds?.length}
                                            />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            {this.state.showDisabledForFeatureFlags &&
                                                <DisableForFeatureFlagOptions
                                                    appContainer={this.props.appContainer}
                                                    onChange={(value) => { props.setFieldValue("disabledForFeatureFlagIds", value) }}
                                                    selectedFeatureFlags={props.values.disabledForFeatureFlagIds}
                                                />
                                            }
                                            <ContentSectionTitle title="Question Answer Conversation" icon={getFeatureTypeIcon(QuestionAnswerFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector disabled={isDisabled} id="contentLanguage" label="Language / Region" languages={props.application.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(false, isDisabled)}
                                        </ContentCollapsingSection>
                                        <ContentCollapsingSection title="User Says" icon={userIcon} id="userSays" titleRightRowComponent={
                                            <div className={menuWrapperStyle}>
                                                <CountButton
                                                    isActive={this.state.showValidation}
                                                    onClick={() => { this.toggleValidation(); }}
                                                    activeIcon={blueValidationIcon}
                                                    inactiveIcon={validationIcon}
                                                    showCount={false}
                                                    text="Validation"
                                                />
                                            </div>
                                        }>
                                            {this.state.showValidation &&
                                                <HighlightSection>
                                                    <ContentCard title="Confidence Score" icon={confidenceScoreIcon}>
                                                        <FormFieldsContainer>
                                                            <Description>You may provide a score between 0 and 1 to indicate how closely you’d like what a user says to match one of the input variations you provide. If this threshold is not met, the content will not be returned.</Description>
                                                            <HorizontalOptionsSelector name="minimumConfidenceScore"
                                                                options={this.options}
                                                                value={props.values.minimumConfidenceScore}
                                                                isCustom={false}
                                                                onChange={(value) => { props.setFieldValue("minimumConfidenceScore", value) }} />
                                                        </FormFieldsContainer>
                                                    </ContentCard>
                                                    <ContentCard title="Requires Specific Keywords" icon={keywordsIcon} id="requiredPhrases" >
                                                        <FormFieldsContainer>
                                                            <Description>Type in required keywords or short phrases below. If the user doesn’t say one of the options, they will not engage with this conversation item.</Description>
                                                            <FieldArray validateOnChange={false} name="requiredPhrases" render={arrayHelpers => (
                                                                <div>
                                                                    {props.values.requiredPhrases?.map((requiredPhrase, index) => (
                                                                        <>
                                                                            <div key={"requiredPhrases" + index}>
                                                                                <TextFieldSubtitle titleText={`Keyword #${index + 1}`} canDelete={true} onDelete={() => arrayHelpers.remove(index)} canCopy={false} />
                                                                                <TextField
                                                                                    id={`requiredPhrases${index}`}
                                                                                    disabled={isDisabled}
                                                                                    name={`requiredPhrases.${index}`}
                                                                                    value={requiredPhrase}
                                                                                    placeholder="Keyword or Short Phrase"
                                                                                    label="User Must Say"
                                                                                    onChange={props.handleChange}
                                                                                    onBlur={props.handleBlur} />
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                                    <HorizontalSeparator />
                                                                    <Button disabled={isDisabled} themes={["secondary-medium"]} type="button" text="Add a Keyword" onClick={() => arrayHelpers.push("")} />
                                                                </div>
                                                            )} />
                                                        </FormFieldsContainer>
                                                    </ContentCard>
                                                </HighlightSection>
                                            }
                                            <ContentCard
                                                title="Input Options"
                                                icon={inputGroupIcon}
                                                id="inputOptions"
                                                className="ac-faq-input-card"
                                                rightRender={() => <><RequiredContainer><RequiredText>At least one required</RequiredText><RequiredAsterixIcon src={requiredStarIcon} /></RequiredContainer></>}>
                                                <ContentCardSubText>Any of the inputs here could trigger this conversation item.</ContentCardSubText>
                                                <FormFieldsContainer>
                                                    <FieldArray validateOnChange={false} name="questions" render={arrayHelpers => (
                                                        <div>
                                                            {props.values.questions.map((question, index) => (
                                                                <>
                                                                    <div key={"q" + index} className="aFc-faq-input-option">
                                                                        <TextFieldSubtitle titleText={`Input Option #${index + 1}`} canDelete={index > 0} canCopy={true} onDelete={() => arrayHelpers.remove(index)} onCopy={() => arrayHelpers.insert(index, { ...question, questionId: null })} />
                                                                        <TextField
                                                                            id={`q${index}`}
                                                                            disabled={isDisabled}
                                                                            name={`questions.${index}.content`}
                                                                            value={question.content}
                                                                            placeholder="A Phrase"
                                                                            label="User Says"
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur} />
                                                                    </div>
                                                                </>
                                                            ))}
                                                            <HorizontalSeparator />
                                                            <Button disabled={isDisabled} themes={["secondary-medium"]} type="button" text="Add a Variation" onClick={() => arrayHelpers.push({
                                                                content: ''
                                                            })} />
                                                        </div>
                                                    )} />
                                                </FormFieldsContainer>
                                            </ContentCard>
                                        </ContentCollapsingSection>
                                        <ResponsesManager applicationId={this.props.application.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur} />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={this.props.application.id}
                                key={this.props.values.responseContainer ? this.props.values.responseContainer.id : ''}
                                endSession={!this.props.values.responses?.some(r => r.followUp != null)}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: props.values.questions.map((q, index) => ({ content: q.content, highlights: [q.content], id: `q${index}` })),
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />
                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application.id, this.props.questionAnswer?.id, QuestionAnswerFeatureTypeId, this.props.values.webhookChainingType)}
            </>
        )
    }
}

const ValidationImage = styled.img`
    margin-left: 8px;
`
const menuWrapperStyle = css`
    margin-right: 8px;
    margin-left: auto;
    display: flex;
    align-items: center;
    .validation-button {
        height: 32px;
        padding: 0 24px;
        background: ${color_gradients_silver_shift};
        border: ${style_border_default};
        box-shadow: none;
        border-radius: 16px;
        box-sizing: border-box;
        color: ${color_text_default};
        margin-right: 16px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: 'Muli';
    }
    .toggled {
        background: ${color_variants_ocean_light_opaque};
        border: 1px solid ${color_colors_ocean};
        color: ${color_colors_ocean};
    }
`
const expanded = css`
    display: block;
`
const collapsed = css`
    display: none;
`
const RequiredContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: 16px;
`
const RequiredAsterixIcon = styled.img`
    color: ${color_colors_decline};
    width: 10px;
`
const RequiredText = styled.p`
    size: 12px;
    margin-right: 9px;
    color: ${color_shades_darkest};
`
const ContentCardSubText = styled.p`
    margin-top: 8px;
    size: 12px;
    line-height: 16px;  
    color: ${color_shades_darker};
`
const Description = styled.p`
    margin-bottom: 32px;
`

export default QuestionAnswerForm;