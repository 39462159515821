import React from 'react';
import ExitMessageContainer from "../../state/containers/ExitMessageContainer";
import PageContainer from '../../components/structure/PageContainer';
import ExitMessageModel from '../../models/features/api/Exit/ExitMessageModel';
import TitleTextField from '../../components/forms/TitleTextField';
import ConversationFlow from '../conversationFlow';
import Loader from '../../components/general/Loader';
import IResult from '../../models/result/IResult';
import { ExitMessagesFeatureTypeId } from '../../constants/featureTypeIds';
import ContentLanguageMultiSelector from '../../components/forms/ContentLanguageMultiSelector';
import { TreeItem } from '../../components/structure/NestedTree/NestedTree';
import ContentPagePanel from '../contentPage';
import RelatedContentMenu from '../relatedContentMenu';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import PanelTitle from '../contentPage/components/PanelTitle';
import ContentCollapsingSection from '../../components/structure/ContentCollapsingSection';
import ContentSectionTitle from '../contentPage/components/ContentSectionTitle';
import { getFeatureTypeIcon } from '../../models/extensions';
import PageError from '../../components/general/PageError';
import ConversationItemActionButtonToolbar from './components/ConversationItemActionButtonToolbar';
import { ContentItemPageState } from './BaseConversationItemForm';
import TemplateLockedBanner from '../../components/general/TemplateLockedBanner';
import ConversationItemFieldSet from '../../components/forms/ConversationItemFieldSet';
import ContentInfoOptions from '../../components/structure/ContentInfoOptions';
import { WebhookChainingType } from '../../models/webhooks/api/WebhookChainingType';
import ExitMessageResponseModel from '../../models/features/api/Exit/ExitMessageResponseModel';
import ResponseGroup from '../../models/features/ResponseGroup';
import ResponsesManager from '../responsesManager';
import { DirtyFormPrompt } from './components/DirtyFormPrompt';
import FollowUpWithRepromptForm, { FollowUpWithRepromptFormData, FollowUpWithRepromptFormProps } from '../followUpForm';
import SensitiveDataOptions from '../contentPage/components/SensitiveDataOptions';
import DisableForFeatureFlagOptions from '../contentPage/components/DisableForFeatureFlagOptions';
const contentInfoIcon = require('../../content/images/content-tree-icons/content-level/task-list-pin.svg');

export interface EditExitMessageFormData extends FollowUpWithRepromptFormData {
    title?: string
    responses: ExitMessageResponseModel[]
    webhookChainingType?: WebhookChainingType
}
export interface EditExitMessageFormProps extends FollowUpWithRepromptFormProps<ExitMessageModel> {
    stateContainer: ExitMessageContainer
    displayTextOverride?: string
    exitMessage?: ExitMessageModel
    isSaving: boolean
}

class ExitMessageForm extends FollowUpWithRepromptForm<ExitMessageModel, EditExitMessageFormProps, EditExitMessageFormData, ContentItemPageState> {
    constructor(props) {
        super(props);
        this.state = {
            isRelinkOpen: false
        }
    }
    handleAsyncSubmit(onSuccess: (result: IResult<ExitMessageModel>) => void) {
        if (this.manualValidate())
            return this.props?.onSubmit(this.props.stateContainer.mapFormDataToModel(this.props.exitMessage, this.props.values), onSuccess);
    }
    handleDiscardChanges() {
        this.handleFinish();
    }
    handleDelete() {
        const confirmed = confirm("Are you sure you want to delete this?");
        if (confirmed) {
            this.props.stateContainer.delete(this.props.exitMessage?.id).then(() => {
                this.handleFinish();
            });
        }
    }
    handleCopy() {
        this.handleAsyncSubmit(() => {
            this.props.stateContainer.copy(this.props.exitMessage?.id).then(r2 => {
                if (r2.resultType == "Ok") {
                    this.props.history.push(`/v/${r2.data.applicationFeatureId}/exitmessages/${r2.data.id}`);
                }
            });
        });
    }

    getMenuTreeRoots() {
        var roots: TreeItem[] = [this.getContentInfoTreeItem(),
        ...this.getResponsesTreeItems(this.props.values.responses)]

        return roots;
    }
    getContentItem() {
        return this.props.exitMessage;
    }
    handleContentItemOptionSelected(option: string, event: any) {
        event.stopPropagation();
        if (option == "Manage Webhooks") {
            this.showWebhookInstanceManager();
        }
        else if (option == "Duplicate") {
            this.handleCopy();
        }
        else if (option == "Delete") {
            this.handleDelete();
        }
    }
    handleResponsesChange(responseGroups: ResponseGroup[]) {
        this.props.setFieldValue("responses", this.getResponsesFromGroups(responseGroups));
    }
    render() {
        const props = { ...this.props };
        const isLoading = props.stateContainer.state.isSaving;
        const isDisabled = isLoading || this.props.isTemplateOwned;
        const responseGroups = this.getResponseGroups(this.props.values.responses);
        if (this.props.responseStateContainer.state.isLoading || !this.props.application) {
            return <Loader />
        }
        return (
            <>
                <form onSubmit={props.handleSubmit}>
                    <DirtyFormPrompt allowPrompt={props.status?.submitting != true} />
                    <ContentPagePanel
                        relatedContentRender={() => (<RelatedContentMenu
                            parentContentId={this.props.previousContentId}
                            conversationItem={{ ...this.props.exitMessage, featureTypeId: ExitMessagesFeatureTypeId }}
                            history={this.props.history}
                            featureTypeId={ExitMessagesFeatureTypeId}
                            followUp={null}
                            contentId={this.props.exitMessage?.id}
                            onClose={this.props.onToggleNavigation}
                            onSave={this.handleAsyncSubmit.bind(this)} />)
                        }
                        treeRender={this.renderTree.bind(this)}
                        formPanelRender={() => (
                            <PageContainer fill>
                                <ConversationItemActionButtonToolbar
                                    errors={Object.values(props.errors)}
                                    validateForm={props.validateForm}
                                    publishDisabled={this.props.assistantApplication == null}
                                    saveDisabled={Object.keys(props.errors).length > 0}
                                    contentItem={props.exitMessage}
                                    isLoading={isLoading}
                                    handleDiscardChanges={this.handleDiscardChanges.bind(this)}
                                    handlePublishing={this.handleSaveAndPublish.bind(this)}
                                    submitForm={props.submitForm}
                                />
                                {props.exitMessage?.createdFromId && <TemplateLockedBanner isLoading={props.stateContainer.state.isTogglingSync} isLinked={props.isTemplateOwned} onRelink={this.handleToggleRelinkModal.bind(this)} onUnlink={() => this.props.onToggleSync(this.props.exitMessage.id, true)} />}

                                <ConversationItemFieldSet disabled={isDisabled} withBanner={props.exitMessage?.createdFromId != null}>
                                    <ScrollablePanelBody className="scrollable-body">
                                        <PanelTitle title="Content Editing"
                                            detail="Here’s where you can fill in the info about this content and determine the course of the conversation by filling in the relevant details.">
                                        </PanelTitle>
                                        <ContentCollapsingSection title="Content Info" id="contentInfo" icon={contentInfoIcon} titleRightRowComponent={
                                            <ContentInfoOptions webhookCount={this.props.stateContainer.state.webhookUpdates?.webhookInstances?.length ?? this.props.stateContainer.state.attachedWebhooks?.length ?? 0}
                                                options={this.getOptions(this.props.exitMessage != null)}
                                                optionSelected={this.handleContentItemOptionSelected.bind(this)}
                                                manageWebhooks={this.showWebhookInstanceManager.bind(this)}
                                                showSensitiveConversation={this.state.showSensitiveConversation}
                                                toggleSensitiveConversation={() => { this.toggleSensitiveConversation(); }}
                                                showDisabledForFeatures={this.state.showDisabledForFeatureFlags}
                                                toggleDisabledForFeatures={() => { this.toggleDisableForFeatureFlags(); }}
                                                flagsSelected={props.values.disabledForFeatureFlagIds?.length}
                                            />
                                        }>
                                            {this.state.showSensitiveConversation &&
                                                <SensitiveDataOptions
                                                    selectedOption={props.values.sensitiveConversationChange ?? "None"}
                                                    onChange={(value) => { props.setFieldValue("sensitiveConversationChange", value) }}
                                                />
                                            }
                                            {this.state.showDisabledForFeatureFlags &&
                                                <DisableForFeatureFlagOptions
                                                    appContainer={this.props.appContainer}
                                                    onChange={(value) => { props.setFieldValue("disabledForFeatureFlagIds", value) }}
                                                    selectedFeatureFlags={props.values.disabledForFeatureFlagIds}
                                                />
                                            }
                                            <ContentSectionTitle title="Exit Message Conversation" icon={getFeatureTypeIcon(ExitMessagesFeatureTypeId, true)} />
                                            <TitleTextField id="contentTitle" disabled={isDisabled} name="title" placeholder="For display only" value={props.values.title} label="Title" onChange={props.handleChange} onBlur={props.handleBlur} />
                                            <ContentLanguageMultiSelector id="contentLanguage" disabled={isDisabled} label="Language / Region" languages={props.application?.languages} selectedLanguageIds={props.values.languages.map(l => l.id)} onChange={this.handleLanguagesChange.bind(this)} />
                                            {this.renderOptions(false, isDisabled)}
                                        </ContentCollapsingSection>

                                        <ResponsesManager applicationId={this.props.application?.id}
                                            applicationFeatureId={this.props.applicationFeatureId}
                                            languages={this.props.values.languages}
                                            responseGroups={responseGroups}
                                            getConditionsKey={(c) => this.getConditionsKey(c)}
                                            isDisabled={isDisabled}
                                            onChange={this.handleResponsesChange.bind(this)}
                                            onBlur={this.props.handleBlur}
                                            hideFollowUp />
                                    </ScrollablePanelBody>
                                </ConversationItemFieldSet>
                                <PageError errors={this.props?.stateContainer?.state?.errors ?? []} onClear={this.handleClearError.bind(this)} />
                            </PageContainer>
                        )}
                        flowPanelRender={() => (
                            <ConversationFlow
                                applicationId={props.application?.id}
                                key={props.values.responseContainer ? props.values.responseContainer.id : ''}
                                endSession={true}
                                languages={this.props.values.languages}
                                conversationItems={[{
                                    requests: [{ content: "Exit", highlights: [], id: "1" }, { content: "Cancel", highlights: [], id: "2" }],
                                    requestTitle: "User Question",
                                    children: this.getPreviewResponses(props.values.responses)
                                }]} />
                        )}>
                    </ContentPagePanel>
                </form>
                {this.renderRelinkSyncWarning()}
                {this.renderWebhookInstanceManager(this.props.application?.id, this.props.exitMessage?.id, ExitMessagesFeatureTypeId, this.props.values.webhookChainingType)}
            </>
        )
    }
}

export default ExitMessageForm;