import { withFormik } from "formik";
import * as yup from 'yup';
import SimpleChoiceForm, { SimpleChoiceFormProps, SimpleChoiceFormData } from '../../formScenes/SimpleChoiceForm';

const EditSimpleChoiceForm = withFormik<SimpleChoiceFormProps, SimpleChoiceFormData>({
    mapPropsToValues: (props: SimpleChoiceFormProps) => {
        var values: SimpleChoiceFormData = {
            title: props.simpleChoice.title,
            choiceType: props.simpleChoice.choiceType,
            responses: props.simpleChoice.responses.length > 0 ? props.simpleChoice.responses : [{ content: '' }],
            requiresParent: true,
            languages: props.simpleChoice.languages,
            isLive: props.simpleChoice.isLive,
            webhookChainingType: props.simpleChoice.webhookChainingType,
            sensitiveConversationChange: props.simpleChoice.sensitiveConversationChange ?? "None",
            disabledForFeatureFlagIds: props.simpleChoice?.disabledForFeatureFlagIds,
        };
        return values;
    },
    validateOnMount: true,
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation")
    }),
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.simpleChoice, values));
    }
})(SimpleChoiceForm);

export default EditSimpleChoiceForm;