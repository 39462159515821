import { withFormik } from "formik";
import * as yup from 'yup';
import CustomRequestForm, { CustomRequestContentFormProps, CustomRequestContentFormData } from '../../formScenes/CustomRequestForm';

const UpdateCustomRequestContentForm = withFormik<CustomRequestContentFormProps, CustomRequestContentFormData>({
    mapPropsToValues: (props: CustomRequestContentFormProps) => {
        var values: CustomRequestContentFormData = {
            title: props.customRequest.title,
            requestTypes: props.customRequest.requestTypes == null ? "" : props.customRequest.requestTypes,
            requestNames: props.customRequest.requestNames == null ? "" : props.customRequest.requestNames,
            platformFilter: props.customRequest.platformFilter == null ? "" : props.customRequest.platformFilter,
            responses: (props.customRequest.responses.length > 0) ? props.customRequest.responses : [{ content: '' }],
            requiresParent: props.customRequest.requiresParent,
            languages: props.customRequest.languages,
            isLive: props.customRequest.isLive,
            webhookChainingType: props.customRequest.webhookChainingType,
            sensitiveConversationChange: props.customRequest.sensitiveConversationChange ?? "None",
            disabledForFeatureFlagIds: props.customRequest?.disabledForFeatureFlagIds,
            alwaysOpen: props.customRequest.alwaysOpen ?? false
        };

        return values;
    },
    validationSchema: yup.object().shape({
        title: yup.string().required("You must enter a title"),
        responses: yup.array().of(
            yup.object().shape({
                content: yup.string().required("You must enter a response variation")
            })
        ).required("You must enter at least 1 response variation").min(1, "You must enter at least 1 response variation"),
        requestTypes: yup.string(),
        requestNames: yup.string().when('requestTypes', {
            is: (val) => { return !val },
            then: yup.string().required("You must enter at least one request name or request type"),
            otherwise: yup.string()
        })
    }),
    validateOnMount: true,
    handleSubmit: (values, { props, setStatus }) => {
        setStatus({
            submitting: true
        });
        return props.onSubmit(props.stateContainer.mapFormDataToModel(props.customRequest, values));
    }
})(CustomRequestForm);

export default UpdateCustomRequestContentForm;